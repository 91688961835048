import {Button, Label, ModalBody, ModalFooter, Spinner} from "reactstrap";
import {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import Select from "react-select";
import FormHelper from "../../helpers/form";
import Auth from "../../api/auth";
import roles from '../../data/roles.json'
import Permissions from "../../api/permissions";
import LocalStorageManager from "../../helpers/localStorageManager";

const RoleModal = ({fetchData, modal, setModal}) => {
    const [loader, setLoader] = useState(false)
    const [isFetching, setIsFetching] = useState(true)
    const [permissions, setPermissions] = useState([])

    const {handleSubmit, control, setValue, formState: {errors}} = useForm()

    const statuses = [
        {
            value: true,
            label: 'Aktiv'
        },
        {
            value: false,
            label: 'Deaktiv'
        }
    ]

    const fetchRoles = async () => {
        setIsFetching(true)
        setValue('role_id', Object.values(roles)?.find(item => item?.value === modal?.role_id))
        setValue('approved', statuses?.find(item => item?.value === modal?.approved))
        setIsFetching(false)
    }

    const submit = async (values) => {
        setLoader(true)
        try {
            await Auth.updateRole(modal?.id, {
                role_id: values?.role_id?.value,
                permissionId: values?.permissionId?.value,
                approved: values?.approved?.value
            })
            fetchData()
            setLoader(false)
            setModal(false)
            if (modal?.id === LocalStorageManager.get('user')?.id) {
                window.location.href
            }
        } catch (e) {
            FormHelper.setApiErrors(e.response.data)
        } finally {
            setLoader(false)
        }
    }


    const fetchPermissions = async () => {
        setIsFetching(true)
        const {data} = await Permissions.get()
        const permissions = FormHelper.convertToSelectValue(data)
        setPermissions(permissions)
        setValue('permissionId', permissions?.find(item => item?.value === modal?.permissionId?.id))
        setIsFetching(false)
    }

    useEffect(() => {
        fetchRoles()
        fetchPermissions()
    }, [modal])

    return (
        <form onSubmit={handleSubmit(submit)}>
            <ModalBody>
                {isFetching ? (
                    <div className="d-flex justify-content-center">
                        <Spinner color="primary" size="xs"/>
                    </div>
                ) : (
                    <>
                        <div className="mb-3">
                            <Label for="approved">Status</Label>
                            <Controller render={({field: {value, onChange}}) => (
                                <Select
                                    options={statuses}
                                    type="text"
                                    name="approved"
                                    id="name"
                                    value={value}
                                    onChange={onChange}
                                />
                            )} name="approved" control={control}/>
                            {FormHelper.generateFormFeedback(errors, 'approved', errors?.approved?.message)}
                        </div>
                        <div className="mb-3">
                            <Label for="role_id">Rol</Label>
                            <Controller render={({field: {value, onChange}}) => (
                                <Select
                                    options={Object.values(roles)}
                                    type="text"
                                    name="role_id"
                                    id="role_id"
                                    value={value}
                                    onChange={onChange}
                                />
                            )} name="role_id" control={control}/>
                            {FormHelper.generateFormFeedback(errors, 'role_id', errors?.role_id?.message)}
                        </div>
                        <div className="mb-3">
                            <Label for="role_id">Səlahiyyət</Label>
                            <Controller render={({field: {value, onChange}}) => (
                                <Select
                                    options={permissions}
                                    type="text"
                                    name="permissionId"
                                    id="permissionId"
                                    value={value}
                                    onChange={onChange}
                                />
                            )} name="permissionId" control={control}/>
                            {FormHelper.generateFormFeedback(errors, 'permissionId', errors?.permissionId?.message)}
                        </div>
                    </>
                )}
            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-end gap-1">
                    <Button outline type="button" onClick={() => setModal(false)} color="secondary">Bağla</Button>
                    <Button disabled={loader} type="submit" color="primary">
                        {loader ? <Spinner color="light" size="sm"/> : 'Yadda saxla'}
                    </Button>
                </div>
            </ModalFooter>
        </form>
    )
}

export default RoleModal
