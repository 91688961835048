import axios from './index'

class Auth {
    login = data => axios.post('users/login', data)
    loginAsUser = data => axios.post('/users/login-as-user', data)
    auth = (data) => axios.post('users/auth', data, {
        skipToast: true
    })
    register = data => axios.post('users/register', data)
    getActiveUsers = params => axios.get('users/active', {params})
    update = data => axios.put('users/update', data)
    updateRole = (id, data) => axios.put(`users/update/role/${id}`, data)
    get = params => axios.get('users', {params})
    delete = () => axios.delete('users')
}

export default new Auth()
