import axios from "./index";

class Curation {
    get = params => axios.get('curations', {params})
    getById = id => axios.get(`curations/${id}`)
    create = data => axios.post('curations', data)
    update = (data, id) => axios.put(`curations/${id}`, data)
    delete = id => axios.delete(`curations/${id}`)
}

export default new Curation()
