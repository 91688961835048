import {Controller, useFieldArray, useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {Button, Card, CardBody, Col, Input, Label, ModalBody, Row, Spinner} from "reactstrap";
import moment from "moment";
import Form from "../../helpers/form";
import Flatpickr from "react-flatpickr";
import Appeal from "../../api/appeal";
import appealStatuses from '../../data/appealStatuses.json'
import appealSources from '../../data/appealSources.json'
import roles from '../../data/roles.json'
import Select from "react-select";
import User from "../../helpers/user";
import Auth from "../../api/auth";
import Curation from "../../api/curation";
import 'flatpickr/dist/l10n/az.js';

const AddAppeal = ({fetchAppeals, setModal, modal}) => {
    const id = modal?.data
    const {control, handleSubmit, setValue, getValues, setError, watch, formState: {errors}} = useForm({
        defaultValues: {
            phones: [{
                phone: '',
            }],
            feedbacks: [{
                feedback: '',
                date: new Date()
            }],
            nextCallDate: new Date(moment(new Date()).add(7, 'days')),
            status: appealStatuses[0]
        }
    })


    const phonesField = useFieldArray({
        control,
        rules: {
            validate: data => {
                return data.every(item => item.phone)
            }
        },
        name: 'phones'
    })

    const [loader, setLoader] = useState(false)
    const [logs, setLogs] = useState([])
    const [experts, setExperts] = useState([])
    const status = watch('status')?.value

    const fetchAuthUserCuration = async () => {
        const {data} = await Curation.get({userId: User.getAuthUser()?.id})
        return data?.[0]
    }

    const fetchExperts = async (curation) => {
        const {data} = await Auth.getActiveUsers({role_id: roles.educationExpert.value})
        setExperts(data?.map(item => {
            const user = {
                value: item.id,
                label: `${item.name} ${item.surname}`
            }

            if (item.id === User.getAuthUser().id && User.isEducationExpert()) {
                setValue('expertId', user)
            } else if (User.isAssistant()) {
                const curationUser = curation?.curationUserId
                setValue('expertId', {
                    value: curationUser?.id,
                    label: `${curationUser?.name} ${curationUser?.surname}`
                })
            }

            return user
        }))
    }

    const formatData = data => {
        setLogs(data?.log_ids)
        setValue('fullName', data?.fullName)
        setValue('status', appealStatuses.find(item => item.value === data.status))
        setValue('reason', data?.reason)
        setValue('applicantFullName', data?.applicantFullName)
        setValue('appealSource', appealSources.find(item => item.label === data?.appealSource))
        setValue('interest', data?.interest)
        setValue('nextCallDate', data?.nextCallDate ? data?.nextCallDate : null)
        if (!data?.nextCallDate) {
            setValue('hasNotNextCallDate', true)
        }
        setValue('phones', data?.phones)
        data?.expertId && setValue('expertId', {
            value: data?.expertId?.id,
            label: `${data?.expertId?.name} ${data?.expertId?.surname}`
        })
    }

    const fetchAppeal = async (dataId = id) => {
        const {data} = await Appeal.getById(dataId)
        formatData(data)
    }


    const submit = async values => {
        const formData = {
            ...values,
            status: values?.status?.value,
            nextCallDate: Form.convertFormDate(values?.nextCallDate),
            appealSource: values?.appealSource?.label,
            expertId: values?.expertId?.value
        }
        setLoader(true)
        try {
            await Appeal[id ? 'update' : 'create'](formData, id)
            if (id) {
                setValue('log', '')
                fetchAppeal()
            } else {
                setModal({})
            }
            fetchAppeals(false)
        } catch (e) {
            Form.setApiErrors(e.response.data, setError)
            fetchAppeal(e.response.data?.data?.id)
        } finally {
            setLoader(false)
        }
    }

    const copyText = () => {
        const data = getValues()?.applicantFullName
        setValue('fullName', data)

    }

    const fetchData = async () => {
        fetchAuthUserCuration().then(curation => {
            fetchExperts(curation)
        })
    }

    useEffect(() => {
        fetchData().then(() => {
            if (id) {
                fetchAppeal()
            }
        })
    }, [id])

    const hasNotNextCallDate = watch('hasNotNextCallDate')

    useEffect(() => {
        if (hasNotNextCallDate) {
            setValue('nextCallDate', null)
        }
    }, [hasNotNextCallDate])

    return (
        <form onSubmit={handleSubmit(submit)}>
            <ModalBody className="bg-light">
                <Row>
                    <Col sm={12} md={6}>
                        <div className="d-flex flex-column">
                            <Card>
                                <CardBody>
                                    <div className="mb-3">
                                        <Label for="applicantFullName">Müraciətçinin ad soyadı</Label>
                                        <div className="d-flex gap-1 align-items-start justify-content-between">
                                            <div className="w-100">
                                                <Controller
                                                    rules={{required: true}}
                                                    render={({field: {value, onChange}}) => (
                                                        <Input
                                                            name="applicantFullName"
                                                            value={value}
                                                            onChange={onChange}
                                                            invalid={errors?.applicantFullName}
                                                        />
                                                    )} name="applicantFullName" control={control}/>
                                                {Form.generateFormFeedback(errors, 'applicantFullName', errors?.applicantFullName?.message)}
                                            </div>
                                            <Button color="warning" onClick={copyText}>
                                                <i className="bx bx-transfer-alt"/>
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <Label for="fullName">Tələbənin ad soyadı</Label>
                                        <Controller
                                            render={({field: {value, onChange}}) => (
                                                <Input
                                                    name="fullName"
                                                    value={value}
                                                    onChange={onChange}
                                                    invalid={errors?.fullName}
                                                />
                                            )} name="fullName" control={control}/>
                                        {Form.generateFormFeedback(errors, 'fullName', errors?.fullName?.message)}
                                    </div>
                                    <div className="mb-3">
                                        <Label for="expertId">Mütəxəssis</Label>
                                        <Controller
                                            render={({field: {value, onChange}}) => (
                                                <Select
                                                    placeholder="Seçim edin"
                                                    isDisabled={User.isEducationExpert() || User.isAssistant()}
                                                    options={experts}
                                                    value={value}
                                                    onChange={onChange}/>
                                            )} name="expertId" control={control}/>
                                    </div>
                                    <div className="mb-3">
                                        <Label for="status">Status</Label>
                                        <Controller
                                            render={({field: {value, onChange}}) => (
                                                <Select
                                                    options={appealStatuses}
                                                    value={value}
                                                    onChange={onChange}/>
                                            )} name="status" control={control}/>
                                    </div>
                                    {status === 5 && (
                                        <div className="mb-3">
                                            <Label for="status">Niyə maraqlı deyil</Label>
                                            <Controller
                                                render={({field: {value, onChange}}) => (
                                                    <Input
                                                        name="reason"
                                                        type="textarea"
                                                        rows={5}
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                )} name="reason" control={control}/>
                                        </div>
                                    )}
                                    <div className="mb-3">
                                        <Label for="appealSource">Hardan tapıb</Label>
                                        <Controller
                                            render={({field: {value, onChange}}) => (
                                                <Select
                                                    placeholder="Seçim edin"
                                                    name="appealSource"
                                                    options={appealSources}
                                                    value={value}
                                                    onChange={onChange}
                                                    invalid={errors?.appealSource}
                                                />
                                            )} name="appealSource" control={control}/>
                                        {Form.generateFormFeedback(errors, 'appealSource', errors?.appealSource?.message)}
                                    </div>
                                    <div className="mb-3">
                                        <Label for="interest">Marağı</Label>
                                        <Controller
                                            render={({field: {value, onChange}}) => (
                                                <Input
                                                    name="interest"
                                                    value={value}
                                                    onChange={onChange}
                                                    invalid={errors?.interest}
                                                />
                                            )} name="interest" control={control}/>
                                        {Form.generateFormFeedback(errors, 'interest', errors?.interest?.message)}
                                    </div>
                                    <div className="mb-3">
                                        <Label for="nextCallDate">Növbəti zəng tarixi</Label>
                                        <Controller
                                            render={({field: {value, onChange}}) => (
                                                <Flatpickr
                                                    className="form-control d-block"
                                                    value={value}
                                                    onChange={onChange}
                                                    options={{
                                                        locale: 'az'
                                                    }}
                                                    disabled={hasNotNextCallDate}
                                                />
                                            )} name="nextCallDate" control={control}/>
                                    </div>
                                    <div className="mb-2">
                                        <Controller render={({field: {value, onChange}}) => (
                                            <Label className="d-flex gap-1">
                                                <div className="form-check">
                                                    <input type="checkbox"
                                                           value={value}
                                                           checked={value}
                                                           onChange={onChange}
                                                           name={value}
                                                           className="form-check-input"/>
                                                </div>
                                                Növbəti zəng tarixi yoxdur
                                            </Label>
                                        )} name="hasNotNextCallDate" control={control}/>
                                    </div>
                                    <div className="mb-3">
                                        <Label for="phone">Telefonlar</Label>
                                        <div className="d-flex flex-column gap-3">
                                            {phonesField.fields.map((item, index) => (
                                                <div className="d-flex flex-column gap-2" key={item.id}>
                                                    <div
                                                        className="d-flex align-items-start justify-content-between gap-1">
                                                        <div className="w-100">
                                                            <Controller
                                                                render={({field: {value, onChange}}) => (
                                                                    <Input
                                                                        name={`phones[${index}].phone`}
                                                                        value={value}
                                                                        onChange={onChange}
                                                                        placeholder="Telefon"
                                                                        invalid={errors?.phones}
                                                                    />
                                                                )} name={`phones[${index}].phone`}
                                                                control={control}/>
                                                            {Form.generateFormFeedback(errors, 'phones', errors?.phones?.message)}
                                                        </div>
                                                        <Button type="button"
                                                                color={index === 0 ? 'success' : 'danger'}
                                                                onClick={() => {
                                                                    if (index === 0) {
                                                                        phonesField.append({
                                                                            phone: '',
                                                                            owner: ''
                                                                        })
                                                                        return
                                                                    }
                                                                    phonesField.remove(index)
                                                                }}>
                                                            <i className={`bx bx-${index === 0 ? 'plus' : 'minus'}`}/>
                                                        </Button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <Button type="submit" color="primary" disabled={loader}>
                                            {loader ?
                                                <Spinner size="sm" color="light"/> : (id ? 'Yenilə' : 'Əlavə et')}
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                    <Col sm={12} md={6}>
                        <div className="d-flex flex-column">
                            <Card>
                                <CardBody className="overflow-auto" style={{maxHeight: '500px'}}>
                                    <div className="mb-3">
                                        <Label for="log">Tarixçə</Label>
                                        <Controller
                                            render={({field: {value, onChange}}) => (
                                                <Input
                                                    name="log"
                                                    type="textarea"
                                                    rows={5}
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            )} name="log" control={control}/>
                                    </div>
                                    <div className="d-flex justify-content-end mb-3">
                                        <Button type="submit" color="primary" disabled={loader}>
                                            {loader ? <Spinner size="sm" color="light"/> : 'Əlavə et'}
                                        </Button>
                                    </div>
                                    {logs?.map((item, index) => (
                                        <div key={item.id}>
                                            <p className="mb-0  font-size-14 d-flex justify-content-between">
                                                <span
                                                    className="fw-bold">{index + 1}. {`${item?.user_id?.name} ${item?.user_id?.surname}`}</span>
                                                <span>{moment(item?.createdAt).format('DD.MM.YYYY | HH:mm')}</span>
                                            </p>
                                            <p className="text-secondary">{item?.log}</p>
                                        </div>
                                    ))}
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
        </form>

    )
}

export default AddAppeal
