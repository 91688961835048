import axios from './index'

class Appeal {
    get = (params) => axios.get('appeals', {params})
    export = (params) => axios.get('appeals/export', {params})
    search = (params) => axios.get('appeals/filters/search', {params})
    getById = id => axios.get(`appeals/${id}`)
    create = data => axios.post('appeals', data)
    update = (data, id) => axios.put(`appeals/${id}`, data)
    updateSettings = (data, id) => axios.post(`appeals/update-settings`, data)
    delete = id => axios.delete(`appeals/${id}`)
}

export default new Appeal()
