import {Controller, useFieldArray, useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import Api from "../../api/tasks";
import FormHelper from "../../helpers/form";
import Form from "../../helpers/form";
import Auth from "../../api/auth";
import {Button, Col, Input, Label, ModalBody, ModalFooter, Row, Spinner} from "reactstrap";
import Curation from "../../api/curation";
import Select from "react-select";
import priorities from '../../data/priorities.json'
import Flatpickr from "react-flatpickr";
import PrevFilesModal from "./PrevFilesModal";
import moment from "moment";
import User from "../../helpers/user";

const TaskModal = ({fetchData, setForm, form, groups, selectedUser}) => {
    const {handleSubmit, control, setValue, getValues, watch, setError, formState: {errors}} = useForm({
        defaultValues: {
            files: [{
                file: null,
            }]
        }
    })
    const {fields, append} = useFieldArray({
        control,
        name: 'files'
    })
    const [loader, setLoader] = useState(false)
    const [isFetching, setIsFetching] = useState(true)
    const [users, setUsers] = useState([])
    const [userCurations, setUserCurations] = useState([])
    const [prevFiles, setPrevFiles] = useState([])

    const submit = async (values) => {
        setLoader(true)
        try {
            const formData = new FormData()
            const data = {
                ...values,
                lastDate: FormHelper.convertFormDate(values.lastDate),
                userId: values?.userId?.value,
                priority: values?.priority?.value,
                groupId: values?.groupId?.value,
            }

            delete data.files
            delete data.additionalUserIds

            Object.keys(data).forEach(item => {
                if (![null, undefined, ''].includes(data[item])) {
                    formData.append(item, data[item])
                }
            })

            values?.additionalUserIds?.forEach(item => {
                formData.append('additionalUserIds', item?.value)
            })


            if (values?.files?.some(item => item?.file)) {
                values?.files?.forEach((item) => {
                    if (item?.file) {
                        formData.append('files', item?.file)
                    }
                })
            }

            if (values?.id) {
                await Api.update(formData, values?.id)
            } else {
                await Api.create(formData)
            }
            fetchData()
            setForm(false)
        } catch (e) {
            FormHelper.setApiErrors(e.response.data, setError)
        } finally {
            setLoader(false)
        }
    }

    const fetchUsers = async () => {
        setIsFetching(true)
        const {data} = await Auth.getActiveUsers()
        setUsers(Form.formatUsers(data))
        setIsFetching(false)
    }

    const fetchUserCuration = async (userId) => {
        const {data} = await Curation.get({curationUserId: userId})
        setUserCurations(Form.formatUsers(data?.map(item => item?.userId)))
    }

    useEffect(() => {
        fetchUsers()
    }, [])

    const handleChangeFile = (e, i) => {
        setValue(`files[${i}].file`, e.target.files[0])

        const data = getValues().files
        if (data[i].file && !data?.[i + 1]) {
            append({
                file: null,
            })
        }
    }

    const userId = watch('userId')?.value

    useEffect(() => {
        if (userId) {
            fetchUserCuration(userId)
        }
    }, [userId])

    useEffect(() => {
        const data = form?.data
        setValue('groupId', Form.formatUsers(groups).find(item => item.value === data?.groupId))
        if (data?.id) {
            setValue('id', data?.id)
            setValue('title', data?.title)
            setValue('description', data?.description)
            setValue('userId', users?.find(item => item.value === data?.userId?.id))
            setValue('additionalUserIds', Form.formatUsers(data?.additionalUserIds))
            setValue('priority', priorities.find(item => item.value === data?.priority))
            setValue('lastDate', data?.lastDate ? data?.lastDate : null)
            setPrevFiles(data?.files)
        }
        if (selectedUser && !data?.id) {
            setValue('userId', users?.find(item => item.value === selectedUser))
        }
        if (!User.isAdmin() || !User.isSuperAdmin()) {
            fetchUserCuration(User.getAuthUser()?.id)
        }
    }, [form, users])

    return (
        <form onSubmit={handleSubmit(submit)}>
            <ModalBody>
                {isFetching ? (
                    <div className="d-flex justify-content-center w-100">
                        <Spinner color="primary" size="lg"/>
                    </div>
                ) : (
                    <Row>
                        <Col sm={12} md={7}>
                            <div className="mb-3">
                                <Label for="title">Başlıq</Label>
                                <Controller rules={{required: true}} render={({field: {value, onChange}}) => (
                                    <Input
                                        value={value}
                                        onChange={onChange}
                                        name="title"
                                        id="title"
                                        invalid={errors?.title}
                                    />
                                )} name="title" control={control}/>
                                {Form.generateFormFeedback(errors, 'title', errors?.title?.message)}
                            </div>
                            <div className="mb-3">
                                <Label for="title">Açıqlama</Label>
                                <Controller render={({field: {value, onChange}}) => (
                                    <Input
                                        type="textarea"
                                        rows={5}
                                        value={value}
                                        onChange={onChange}
                                        name="description"
                                        id="description"
                                    />
                                )} name="description" control={control}/>
                            </div>
                            <div className="mb-3">
                                <Label>Fayllar</Label>
                                {fields.map((field, index) => (
                                    <div className="mb-3" key={field.id}>
                                        <Controller render={() => (
                                            <Input
                                                className="form-control mb-3"
                                                onChange={e => handleChangeFile(e, index)}
                                                type="file" accept=".pdf,.png,.jpg,.jpeg,.jfif,.mp4"/>
                                        )} name={`files.${index}.file`} control={control}/>
                                    </div>
                                ))}
                                <PrevFilesModal files={prevFiles} taskId={form?.data?.id} fetchData={fetchData}/>
                            </div>
                        </Col>
                        <Col sm={12} md={5}>
                            <div className="mb-3">
                                <Label>Qrup</Label>
                                <Controller rules={{required: true}} render={({field: {value, onChange}}) => (
                                    <Select
                                        placeholder="Seçim edin"
                                        options={Form.formatUsers(groups)}
                                        value={value}
                                        onChange={onChange}
                                        name="groupId"
                                        id="groupId"
                                        className={errors?.groupId && 'is-invalid'}
                                    />
                                )} name="groupId" control={control}/>
                                {Form.generateFormFeedback(errors, 'groupId', errors?.groupId?.message)}
                            </div>
                            <div className="mb-3">
                                <Label>İstifadəçi</Label>
                                <Controller rules={{required: true}} render={({field: {value, onChange}}) => (
                                    <Select
                                        placeholder="Seçim edin"
                                        options={User.isAdmin() ? users : [...userCurations, {
                                            value: User.getAuthUser()?.id,
                                            label: `${User.getAuthUser()?.name} ${User.getAuthUser()?.surname}`,
                                        }]}
                                        value={value}
                                        onChange={onChange}
                                        name="userId"
                                        id="userId"
                                        className={errors?.userId && 'is-invalid'}
                                    />
                                )} name="userId" control={control}/>
                                {Form.generateFormFeedback(errors, 'userId', errors?.userId?.message)}
                            </div>
                            {userCurations.length > 0 && (
                                <div className="mb-3">
                                    <Label>Digər istifadəçilər</Label>
                                    <Controller render={({field: {value, onChange}}) => (
                                        <Select
                                            placeholder="Seçim edin"
                                            options={userCurations}
                                            isMulti
                                            value={value}
                                            onChange={onChange}
                                            name="additionalUserIds"
                                            id="additionalUserIds"
                                        />
                                    )} name="additionalUserIds" control={control}/>
                                </div>
                            )}
                            <div className="mb-3">
                                <Label>Zəruriyyət dərəcəsi</Label>
                                <Controller rules={{required: true}} render={({field: {value, onChange}}) => (
                                    <Select
                                        placeholder="Seçim edin"
                                        options={priorities}
                                        value={value}
                                        onChange={onChange}
                                        name="priority"
                                        id="priority"
                                        className={errors?.priority && 'is-invalid'}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                backgroundColor: value?.color,
                                            }),
                                        }}
                                    />
                                )} name="priority" control={control}/>
                                {Form.generateFormFeedback(errors, 'priority', errors?.priority?.message)}
                            </div>
                            <div className="mb-3">
                                <Label>Son tarix</Label>
                                <Controller render={({field: {value, onChange}}) => (
                                    <Flatpickr
                                        className="form-control d-block"
                                        placeholder="Seçim edin"
                                        value={value}
                                        onChange={onChange}
                                        options={{
                                            locale: 'az'
                                        }}
                                    />
                                )} name="lastDate" control={control}/>
                            </div>
                            {form?.data?.id && (
                                <div className="mb-3">
                                    <b>{form?.data?.assigneeId?.name} {form?.data?.assigneeId?.surname}</b>{' '}
                                    tərəfindən{' '}
                                    <b>{moment(form?.data.createdAt).format('DD.MM.YYYY HH:mm')}</b>{' '}
                                    tarixində yaradıldı
                                </div>
                            )}
                        </Col>
                    </Row>
                )}
            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-end gap-1">
                    <Button outline type="button" onClick={() => setForm({})} color="secondary">Bağla</Button>
                    <Button disabled={loader} type="submit" color="primary">
                        {loader ? <Spinner color="light" size="sm"/> : 'Yadda saxla'}
                    </Button>
                </div>
            </ModalFooter>
        </form>
    )
}

export default TaskModal
