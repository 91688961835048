import axios from "./index";

class TaskGroups {
    get = (params) => axios.get('task-groups', {params})
    getById = id => axios.get(`task-groups/${id}`)
    create = data => axios.post('task-groups', data)
    update = (data, id) => axios.put(`task-groups/${id}`, data)
    delete = id => axios.delete(`task-groups/${id}`)
}

export default new TaskGroups()
