import Breadcrumbs from "components/Common/Breadcrumb";
import {Badge, Button, Card, CardBody, Col, Modal, ModalHeader, Row, Spinner, UncontrolledTooltip} from "reactstrap";
import {useEffect, useMemo, useState} from "react";
import moment from "moment";
import Auth from "../../api/auth";
import RoleModal from "./RoleModal";
import User from "../../helpers/user";
import roles from '../../data/roles.json'
import AddModal from "./AddModal";
import Form from "../../helpers/form";
import Can from "../../components/Permissions/Can";
import modules from '../../data/modules.json'
import LocalStorageManager from "../../helpers/localStorageManager";
import {toast} from "react-toastify";

const Users = () => {
    const [data, setData] = useState([])
    const [isFetching, setIsFetching] = useState(true)
    const [loader, setLoader] = useState({})
    const [loginLoader, setLoginLoader] = useState(null)
    const [roleModal, setRoleModal] = useState(false)
    const [addModal, setAddModal] = useState(false)

    document.title = 'İstifadəçilər | Azstudy'

    const columns = useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'index',
            },
            {
                Header: 'Ad',
                accessor: 'name'
            },
            {
                Header: 'Soyad',
                accessor: 'surname'
            },
            {
                Header: 'Email',
                accessor: 'email'
            },
            {
                Header: 'Rol',
                accessor: 'role'
            },
            {
                Header: 'Status',
                accessor: 'status'
            },
            {
                Header: 'Yaradılma tarixi',
                accessor: 'createdAt'
            },
            {
                Header: 'Yenilənmə tarixi',
                accessor: 'updatedAt'
            },
            {
                Header: 'Əməliyyatlar',
                accessor: 'actions',
            },
        ],
        []
    );

    const deleteUser = async id => {
        setLoader({
            [id]: true
        })
        await Auth.delete(id)
        fetchData()
        setLoader({})
    }

    const loginAsUser = async id => {
        setLoginLoader(id)
        try {
            const {data} = await Auth.loginAsUser({id})
            localStorage.setItem('token', data?.accessToken)
            LocalStorageManager.set('user', data)
            window.location.href = '/dashboard'
        } catch (e) {
            toast.error('Xəta baş verdi')
        } finally {
            setLoginLoader(false)
        }

    }

    const fetchUsers = async (roles) => {
        const {data} = await Auth.get()
        setData(data?.map((item, index) => {
            const role = Object.values(roles).find(role => role.value === item?.role_id)
            return {
                ...item,
                index: <div className="d-flex align-items-center gap-1">
                    <Badge color="primary">{index + 1}</Badge>
                </div>,
                name: <div className="d-flex align-items-center gap-1">
                    {item?.profile_image ? (
                        <img
                            style={{objectFit: 'cover'}}
                            src={Form.generateFileUrl(item?.profile_image)}
                            alt={item.name}
                            className="avatar-md rounded-circle img-thumbnail"
                        />
                    ) : (
                        <div className="avatar-md rounded-circle img-thumbnail">
                            {User.getFirstChars(item)}
                        </div>
                    )}
                    <span>{item.name}</span>
                </div>,
                role: <Badge color={role?.value === roles?.admin?.value ? 'success' : 'primary'}>{role?.label}</Badge>,
                permissionId: item?.permissionId?.name || 'Yoxdur',
                status: <Badge
                    color={item?.approved ? 'success' : 'secondary'}>{item?.approved ? 'Active' : 'Deactive'}</Badge>,
                createdAt: moment(item?.createdAt).format('DD.MM.YYYY HH:mm'),
                updatedAt: moment(item?.updatedAt).format('DD.MM.YYYY HH:mm'),
                actions: <div className="d-flex gap-1">
                    <Can moduleName={modules.users.value} keys={['can_update']}>
                        <Button
                            type="button"
                            color="primary"
                            disabled={loader?.[item.id]}
                            id={`user-${item.id}-update-role`}
                            onClick={() => setRoleModal(item)}
                        >
                            <i className="bx bx-pencil"/>
                        </Button>
                        <UncontrolledTooltip target={`user-${item.id}-update-role`} placement="bottom">
                            Düzəliş et
                        </UncontrolledTooltip>
                    </Can>
                    {(User.isAdmin() || User.isSuperAdmin()) && item.id !== User.getAuthUser()?.id && (
                        <>
                            <Button
                                type="button"
                                color="success"
                                id={`user-${item.id}-login`}
                                disabled={loginLoader}
                                onClick={() => loginAsUser(item.id)}
                            >
                                {loginLoader === item.id ? <Spinner size="sm" color="light"/> :
                                    <i className="bx bx-lock-alt"/>}
                            </Button>
                            <UncontrolledTooltip target={`user-${item.id}-login`} placement="bottom">
                                {item.name} {item.surname} kimi daxil ol
                            </UncontrolledTooltip>
                        </>
                    )}
                    {/*<Button*/}
                    {/*    type="button"*/}
                    {/*    color="danger"*/}
                    {/*    disabled={loader?.[item.id]}*/}
                    {/*    id={`user-${item.id}-delete`}*/}
                    {/*    onClick={() => deleteUser(item.id)}*/}
                    {/*>*/}
                    {/*    {loader?.[item.id] ? <Spinner size="sm" color="light"/> : <i className="bx bx-trash"/>}*/}
                    {/*</Button>*/}
                </div>
            }
        }))
    }

    const fetchData = async () => {
        setIsFetching(true)
        await fetchUsers(roles)
        setIsFetching(false)
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div className="page-content">
            <Modal className="modal-dialog-centered" isOpen={!!roleModal} toggle={() => setRoleModal(false)}>
                <ModalHeader
                    toggle={() => setRoleModal(false)}>İstifadəçi məlumatların dəyiş</ModalHeader>
                <RoleModal fetchData={fetchData} modal={roleModal} setModal={setRoleModal}/>
            </Modal>
            <Modal className="modal-dialog-centered" isOpen={addModal} toggle={() => setAddModal(false)}>
                <ModalHeader
                    toggle={() => setAddModal(false)}>İstifadəçi əlavə et</ModalHeader>
                <AddModal fetchData={fetchData} setModal={setAddModal}/>
            </Modal>
            <div className="container-fluid">
                <Breadcrumbs breadcrumbItem={`İSTİFADƏÇİLƏR (${data?.length})`}/>
                <Row>
                    <Col sm={12}>
                        <Card>
                            <CardBody>
                                <Can moduleName={modules.users.value} keys={['can_add']}>
                                    <Button
                                        type="button"
                                        color="success"
                                        className="btn-label mb-3"
                                        onClick={() => setAddModal(true)}
                                    >
                                        <i className="bx bx-plus label-icon"/> Əlavə et
                                    </Button>
                                </Can>
                                <div className="table-responsive">
                                    {isFetching ? (
                                        Array.from({length: 10}).map((item, index) => (
                                            <p className="placeholder-glow mb-2 mb-0" key={index}>
                                                <span className="placeholder py-4 col-12"/>
                                            </p>
                                        ))
                                    ) : (
                                        <>
                                            <table className="table table-bordered">
                                                <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Ad</th>
                                                    <th>Soyad</th>
                                                    <th>Email</th>
                                                    <th>Rol</th>
                                                    <th>Səlahiyyət</th>
                                                    <th>Status</th>
                                                    <th>Yaradılma tarixi</th>
                                                    <th>Yenilənmə tarixi</th>
                                                    <th>Əməliyyatlar</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {data.map(item => (
                                                    <tr key={item.id}>
                                                        <td>{item.index}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.surname}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.role}</td>
                                                        <td>{item.permissionId}</td>
                                                        <td>{item.status}</td>
                                                        <td>{item.createdAt}</td>
                                                        <td>{item.updatedAt}</td>
                                                        <td>{item.actions}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </>
                                    )}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>

    )
}

export default Users
