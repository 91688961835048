import {Controller, useForm} from "react-hook-form";
import {Button, Label, ModalBody, ModalFooter, Spinner} from "reactstrap";
import FormHelper from "helpers/form";
import {useEffect, useState} from "react";
import Api from "../../../api/curation";
import Select from "react-select";
import Auth from "../../../api/auth";

const Add = ({fetchData, setForm, form}) => {
    const {handleSubmit, control, setValue, watch, setError, formState: {errors}} = useForm({})
    const [loader, setLoader] = useState(false)
    const [isFetching, setIsFetching] = useState(true)
    const [users, setUsers] = useState([])

    const submit = async (values) => {
        setLoader(true)
        try {
            const formData = {
                userId: values?.userId?.value,
                curationUserId: values?.curationUserId?.value
            }
            if (values?.id) {
                await Api.update(formData, values?.id)
            } else {
                await Api.create(formData)
            }
            fetchData()
            setForm(false)
        } catch (e) {
            FormHelper.setApiErrors(e.response.data, setError)
        } finally {
            setLoader(false)
        }
    }

    const fetchUsers = async () => {
        setIsFetching(true)
        const {data} = await Auth.getActiveUsers()
        setUsers(FormHelper.formatUsers(data))
        setIsFetching(false)
    }

    const userId = watch('userId')?.value
    const curationUsersOptions = users?.filter(item => item?.value !== userId)

    useEffect(() => {
        fetchUsers()
    }, [])

    useEffect(() => {
        const data = form?.data
        if (data && users.length) {
            setValue('id', data?.id)
            setValue('userId', users.find(item => item.value === data.userId?.id))
            setValue('curationUserId', users.find(item => item.value === data.curationUserId?.id))
        }
    }, [form, users])

    return (
        <form onSubmit={handleSubmit(submit)}>
            <ModalBody>
                {isFetching ? (
                    <div className="d-flex justify-content-center w-100">
                        <Spinner color="primary" size="lg"/>
                    </div>
                ) : (
                    <>
                        <div className="mb-3">
                            <Label for="userId">İstifadəçi</Label>
                            <Controller rules={{
                                required: true
                            }} render={({field: {value, onChange}}) => (
                                <Select
                                    options={users}
                                    placeholder="Seçim edin"
                                    name="userId"
                                    id="userId"
                                    value={value}
                                    onChange={onChange}
                                    className={FormHelper.getErrorClassName(errors, 'userId')}
                                />
                            )} name="userId" control={control}/>
                            {FormHelper.generateFormFeedback(errors, 'userId', errors?.userId?.message)}
                        </div>
                        <div className="mb-3">
                            <Label for="curationUserId">Kurasiyasında olduğu istifadəçi</Label>
                            <Controller rules={{
                                required: true
                            }} render={({field: {value, onChange}}) => (
                                <Select
                                    options={curationUsersOptions}
                                    placeholder="Seçim edin"
                                    name="curationUserId"
                                    id="curationUserId"
                                    value={value}
                                    onChange={onChange}
                                    className={FormHelper.getErrorClassName(errors, 'curationUserId')}
                                />
                            )} name="curationUserId" control={control}/>
                            {FormHelper.generateFormFeedback(errors, 'curationUserId', errors?.curationUserId?.message)}
                        </div>
                    </>
                )}
            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-end gap-1">
                    <Button outline type="button" onClick={() => setForm({})} color="secondary">Bağla</Button>
                    <Button disabled={loader} type="submit" color="primary">
                        {loader ? <Spinner color="light" size="sm"/> : 'Yadda saxla'}
                    </Button>
                </div>
            </ModalFooter>
        </form>
    )
}

export default Add
