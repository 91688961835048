import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Label,
    Row,
    Spinner,
    UncontrolledDropdown,
    UncontrolledTooltip
} from "reactstrap";
import {Controller} from "react-hook-form";
import Select from "react-select";
import appealStatuses from "../../data/appealStatuses.json"
import roles from "../../data/roles.json"
import {useEffect, useState} from "react";
import Auth from "../../api/auth";
import User from "../../helpers/user";
import Flatpickr from "react-flatpickr";
import 'flatpickr/dist/l10n/az.js';
import Form from "../../helpers/form";
import Appeal from "../../api/appeal";
import PDFMerger from "pdf-merger-js/browser";

const Filters = ({form, fetchData}) => {
    const [isFetching, setIsFetching] = useState(false)
    const [users, setUsers] = useState([])
    const [downloadLoader, setDownloadLoader] = useState(false)
    const [selectedFilters, setSelectedFilters] = useState({})

    const nextCallDateOptions = [
        {
            value: 1,
            label: 'Bu gün zəng edilməli olanlar'
        },
        {
            value: 2,
            label: 'Vaxtı keçmişlər'
        }
    ]

    const reset = () => {
        setSelectedFilters({})
        form.reset({expertId: null, status: null, nextCallDate: null, nextCallDateType: null, statusDate: null})
        fetchData()
    }

    const submit = async (values) => {
        setSelectedFilters(values)
        setIsFetching(true)
        await fetchData()
        setIsFetching(false)
    }

    const fetchUsers = async () => {
        if (!User.isAdmin()) {
            return
        }
        const {data} = await Auth.getActiveUsers({role_id: roles.educationExpert.value})
        setUsers(Form.formatUsers(data))
    }

    const downloadPdf = async item => {
        const merger = new PDFMerger();
        await merger.add(Form.generateGeneratedFileUrl(item))
        await merger.save('Müraciətlər');
    }

    const exportData = async (as) => {
        setDownloadLoader(true)
        const convertedFilters = {
            ...Form.convertFormData(selectedFilters),
            statusDate: Form.convertFormDate(selectedFilters?.statusDate)
        }
        const data = await Appeal.export({as, ...convertedFilters})
        const contentType = as === 'csv' ? 'text/csv' : 'application/pdf'
        const file = new Blob([data], {type: contentType})
        if (as === 'csv') {
            const a = document.createElement('a');
            a.download = `Müraciətlər.${as}`;
            a.href = window.URL.createObjectURL(file);
            a.className = 'd-none'
            a.dataset.downloadurl = [contentType, a.download, a.href].join(':');
            a.click()
            document.body.appendChild(a);
        } else {
            downloadPdf(data?.data)
        }

        setDownloadLoader(false)
    }

    useEffect(() => {
        fetchUsers()
    }, [])

    return (
        <Card>
            <CardHeader className="shadow">
                <CardTitle>Filtrlər</CardTitle>
            </CardHeader>
            <CardBody>
                <form onSubmit={form.handleSubmit(submit)}>
                    <Row>
                        {(User.isAdmin() || User.isSuperAdmin()) && (
                            <Col sm={12} md={4}>
                                <div className="mb-3">
                                    <Label for="expertId">Mütəxəssis</Label>
                                    <Controller render={({field: {value, onChange}}) => (
                                        <Select
                                            options={users}
                                            placeholder="Seçim edin"
                                            value={value}
                                            isClearable
                                            onChange={onChange}
                                            name="expertId"
                                            id="expertId"
                                        />
                                    )} name="expertId" control={form.control}/>
                                </div>
                            </Col>
                        )}
                        <Col sm={12} md={4}>
                            <div className="mb-3">
                                <div className="d-flex gap-2">
                                    <div className="w-100">
                                        <Label for="status">Status</Label>
                                        <Controller render={({field: {value, onChange}}) => (
                                            <Select
                                                className="w-100"
                                                options={appealStatuses}
                                                placeholder="Seçim edin"
                                                value={value}
                                                isClearable
                                                onChange={onChange}
                                                name="status"
                                            />
                                        )} name="status" control={form.control}/>
                                    </div>
                                    <div className="w-100">
                                        <Label for="status">Status tarixi</Label>
                                        <Controller render={({field: {value, onChange}}) => (
                                            <Flatpickr
                                                className="form-control"
                                                value={value}
                                                onChange={onChange}
                                                placeholder="Seçim edin"
                                                options={{
                                                    locale: 'az',
                                                }}
                                            />
                                        )} name="statusDate" control={form.control}/>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} md={4}>
                            <div className="mb-3">
                                <Label for="nextCallDate">Növbəti zəng tarixi</Label>
                                <div className="d-flex justify-content-between gap-2">
                                    <Controller render={({field: {value, onChange}}) => (
                                        <Flatpickr
                                            className="form-control"
                                            value={value}
                                            onChange={onChange}
                                            placeholder="Seçim edin"
                                            options={{
                                                locale: 'az',
                                            }}
                                        />
                                    )} name="nextCallDate" control={form.control}/>
                                    <Controller render={({field: {value, onChange}}) => (
                                        <Select
                                            className="w-100"
                                            placeholder="Seçim edin"
                                            options={nextCallDateOptions}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )} name="nextCallDateType" control={form.control}/>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12}>
                            <div className="d-flex justify-content-end">
                                <div className="d-flex gap-1">
                                    <Button className="btn-soft-secondary" id="reset" type="button" outline
                                            onClick={reset}><i
                                        className="bx bx-rotate-right"/></Button>
                                    <UncontrolledTooltip target="reset" placement="bottom">Sıfırla</UncontrolledTooltip>
                                    <Button color="primary" disabled={isFetching}>
                                        {isFetching ? <Spinner size="sm" color="light"/> : 'Axtar'}
                                    </Button>
                                    <UncontrolledDropdown>
                                        <DropdownToggle className="btn btn-success"
                                                        disabled={downloadLoader}>
                                            {downloadLoader ? <Spinner size="sm" color="light"/> : 'Yüklə'}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => exportData('csv')}>EXCEL</DropdownItem>
                                            <DropdownItem onClick={() => exportData('pdf')}>PDF</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </form>
            </CardBody>
        </Card>
    )
}

export default Filters
