import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {Col, Row} from "reactstrap";
import Degree from "./Degree";

const Contracts = () => {
    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs breadcrumbItem={`MÜQAVİLƏLƏR STATİSTİKASI`}/>
                <Row>
                    <Col sm={6}>
                        <Degree/>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Contracts
