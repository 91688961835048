import LocalStorageManager from "../../helpers/localStorageManager";

const Can = ({moduleName, keys, children}) => {
    const permissions = LocalStorageManager.get('user')?.permissionId?.access
    const modulePermissions = permissions?.[moduleName]
    let hasAccess = false


    try {
        Object.keys(modulePermissions).forEach(item => {
            if (modulePermissions[item] && keys.includes(item)) {
                hasAccess = true
            }
        })
    } catch (e) {
        hasAccess = false
    }

    return hasAccess ? children : null

}

export default Can
