import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col, DropdownItem, DropdownMenu, DropdownToggle,
    Label,
    Row,
    Spinner,
    UncontrolledDropdown,
    UncontrolledTooltip
} from "reactstrap";
import {Controller} from "react-hook-form";
import Select from "react-select";
import educationTypes from "../../data/educationTypes.json"
import countryCodes from "../../data/countryCodes.json"
import contractStatuses from "../../data/contractStatuses.json"
import documentStatuses from "../../data/documentStatuses.json"
import roles from "../../data/roles.json"
import {useEffect, useState} from "react";
import Auth from "../../api/auth";
import Form from "../../helpers/form";
import degrees from "../../data/degrees.json";
import PDFMerger from "pdf-merger-js/browser";
import Appeal from "../../api/appeal";
import Contract from "../../api/contract";

const Filters = ({form, fetchData}) => {
    const [isFetching, setIsFetching] = useState(false)
    const [users, setUsers] = useState([])
    const [downloadLoader, setDownloadLoader] = useState(false)
    const [selectedFilters, setSelectedFilters] = useState({})

    const reset = () => {
        setSelectedFilters({})
        form.reset({
            educationType: null,
            code: null,
            user_id: null,
            documentStatus: null,
            status: null,
            degree: null
        })
        fetchData()
    }

    const submit = async (values) => {
        setSelectedFilters(values)
        setIsFetching(true)
        await fetchData()
        setIsFetching(false)
    }

    const fetchUsers = async () => {
        const {data} = await Auth.getActiveUsers({role_id: roles.documentBranchExpert.value})
        setUsers(Form.formatUsers(data))
    }

    const downloadPdf = async item => {
        const merger = new PDFMerger();
        await merger.add(Form.generateGeneratedFileUrl(item))
        await merger.save('Müqavilələr');
    }

    const exportData = async (as) => {
        setDownloadLoader(true)
        const convertedFilters = Form.convertFormData(selectedFilters)
        const data = await Contract.export({as, ...convertedFilters})
        const contentType = as === 'csv' ? 'text/csv' : 'application/pdf'
        const file = new Blob([data], {type: contentType})
        if (as === 'csv') {
            const a = document.createElement('a');
            a.download = `Müqavilələr.${as}`;
            a.href = window.URL.createObjectURL(file);
            a.className = 'd-none'
            a.dataset.downloadurl = [contentType, a.download, a.href].join(':');
            a.click()
            document.body.appendChild(a);
        } else {
            downloadPdf(data?.data)
        }

        setDownloadLoader(false)
    }
    useEffect(() => {
        fetchUsers()
    }, [])

    return (
        <Card>
            <CardHeader className="shadow">
                <CardTitle>Filtrlər</CardTitle>
            </CardHeader>
            <CardBody>
                <form onSubmit={form.handleSubmit(submit)}>
                    <Row>
                        <Col sm={12} md={4}>
                            <div className="mb-3">
                                <Label for="user_id">İstifadəçi</Label>
                                <Controller render={({field: {value, onChange}}) => (
                                    <Select
                                        options={users}
                                        placeholder="Seçim edin"
                                        value={value}
                                        isClearable
                                        onChange={onChange}
                                        name="user_id"
                                        id="user_id"
                                    />
                                )} name="user_id" control={form.control}/>
                            </div>
                        </Col>
                        <Col sm={12} md={4}>
                            <div className="mb-3">
                                <Label for="educationType">Əyani/Qiyabi/Distant</Label>
                                <Controller render={({field: {value, onChange}}) => (
                                    <Select
                                        options={Object.values(educationTypes)}
                                        placeholder="Seçim edin"
                                        value={value}
                                        isClearable
                                        onChange={onChange}
                                        name="educationType"
                                        id="educationType"
                                    />
                                )} name="educationType" control={form.control}/>
                            </div>
                        </Col>
                        <Col sm={12} md={4}>
                            <div className="mb-3">
                                <Label for="code">Ölkə</Label>
                                <Controller render={({field: {value, onChange}}) => (
                                    <Select
                                        options={countryCodes}
                                        placeholder="Seçim edin"
                                        value={value}
                                        isClearable
                                        onChange={onChange}
                                        name="code"
                                        id="code"
                                    />
                                )} name="code" control={form.control}/>
                            </div>
                        </Col>
                        <Col sm={12} md={4}>
                            <div className="mb-3">
                                <Label for="documentStatus">Sənədlərin statusu</Label>
                                <Controller render={({field: {value, onChange}}) => (
                                    <Select
                                        options={Object.values(documentStatuses)}
                                        placeholder="Seçim edin"
                                        value={value}
                                        isClearable
                                        onChange={onChange}
                                        name="documentStatus"
                                        id="documentStatus"
                                    />
                                )} name="documentStatus" control={form.control}/>
                            </div>
                        </Col>
                        <Col sm={12} md={4}>
                            <div className="mb-3">
                                <Label for="status">Qəbul prosesi</Label>
                                <Controller render={({field: {value, onChange}}) => (
                                    <Select
                                        options={Object.values(contractStatuses)}
                                        placeholder="Seçim edin"
                                        value={value}
                                        isClearable
                                        onChange={onChange}
                                        name="status"
                                        id="contract-status"
                                    />
                                )} name="status" control={form.control}/>
                            </div>
                        </Col>
                        <Col sm={12} md={4}>
                            <div className="mb-3">
                                <Label for="degree">Dərəcə</Label>
                                <Controller render={({field: {value, onChange}}) => (
                                    <Select
                                        options={Object.values(degrees)}
                                        placeholder="Seçim edin"
                                        value={value}
                                        isClearable
                                        onChange={onChange}
                                        name="degree"
                                        id="contract-status"
                                    />
                                )} name="degree" control={form.control}/>
                            </div>
                        </Col>
                        <Col sm={12}>
                            <div className="d-flex justify-content-end">
                                <div className="d-flex gap-1">
                                    <Button className="btn-soft-secondary" type="button" outline
                                            onClick={reset} id="reset"><i
                                        className="bx bx-rotate-right"/></Button>
                                    <UncontrolledTooltip target="reset" placement="bottom">Sıfırla</UncontrolledTooltip>
                                    <Button color="primary" disabled={isFetching}>
                                        {isFetching ? <Spinner size="sm" color="light"/> : 'Axtar'}
                                    </Button>
                                    <UncontrolledDropdown>
                                        <DropdownToggle className="btn btn-success"
                                                        disabled={downloadLoader}>
                                            {downloadLoader ? <Spinner size="sm" color="light"/> : 'Yüklə'}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => exportData('csv')}>EXCEL</DropdownItem>
                                            <DropdownItem onClick={() => exportData('pdf')}>PDF</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </form>
            </CardBody>
        </Card>
    )
}

export default Filters
