import {PDFDocument} from 'pdf-lib';

const generateRandomNumber = (min, max) => {
    return Math.floor(Math.random() * max) + min;
}

const getOnlyWords = text => {
    const regex = /<[^>]+>/g
    const localText = text.replace(regex, '')
    return localText.split('\n').map(item => item && item.trim()?.replace(new RegExp('#', 'g'), ''))
}

const createPDFFromImage = async (imageUrl) => {
    const imageBytes = await fetch(imageUrl).then((response) => response.arrayBuffer());

    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([596, 1008])

    let image
    const data = {
        png: async () => await pdfDoc.embedPng(imageBytes),
        jpg: async () => await pdfDoc.embedJpg(imageBytes),
        jpeg: async () => await pdfDoc.embedJpg(imageBytes),
    }
    image = await data[imageUrl?.split('.')?.[imageUrl?.split('.')?.length - 1]]()
    const {width, height} = image
    const centerX = (page.getWidth() - width) / 2;
    const centerY = (page.getHeight() - height) / 2;
    page.drawImage(image, {
        x: centerX,
        y: centerY,
        width,
        height,
    });

    const pdfBytes = await pdfDoc.save();
    const pdfBlob = new Blob([pdfBytes], {type: 'application/pdf'});
    return URL.createObjectURL(pdfBlob);
};

export default {
    generateRandomNumber,
    getOnlyWords,
    createPDFFromImage
}
