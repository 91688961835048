import Breadcrumbs from "components/Common/Breadcrumb";
import {Badge, Button, Card, CardBody, Col, Modal, ModalHeader, Row} from "reactstrap";
import {useEffect, useState} from "react";
import moment from "moment";
import PermissionApi from '../../../api/permissions'
import ConfirmModal from "../../../components/Common/ConfirmModal";
import AddPermission from "./AddPermission";
import modules from '../../../data/modules.json'
import Can from "../../../components/Permissions/Can";

const Permissions = () => {
    const [data, setData] = useState([])
    const [isFetching, setIsFetching] = useState(true)
    const [permissionForm, setPermissionForm] = useState({})
    const [confirmModal, setConfirmModal] = useState(false)

    document.title = 'Səlahiyyətlər | Azstudy'

    const deleteRole = async () => {
        await PermissionApi.delete(confirmModal)
        fetchData()
    }

    const fetchData = async () => {
        setIsFetching(true)
        const data = await PermissionApi.get()
        setData(data?.data?.reverse()?.map((item, index) => {
            return {
                ...item,
                index: <Badge color="primary">{index + 1}</Badge>,
                createdAt: moment(item?.createdAt).format('DD.MM.YYYY HH:mm'),
                updatedAt: moment(item?.updatedAt).format('DD.MM.YYYY HH:mm'),
                actions: <div className="d-flex gap-1">
                    <Can moduleName={modules.permissions.value} keys={['can_update']}>
                        <Button
                            type="button"
                            color="primary"
                            id={`permission-${item.id}-edit`}
                            onClick={() => setPermissionForm({
                                status: true,
                                data: item
                            })}
                        >
                            <i className="bx bx-pencil"/>
                        </Button>
                    </Can>
                    <Can moduleName={modules.permissions.value} keys={['can_delete']}>
                        <Button
                            type="button"
                            color="danger"
                            id={`permission-${item.id}-delete`}
                            onClick={() => setConfirmModal(item.id)}
                        >
                            <i className="bx bx-trash"/>
                        </Button>
                    </Can>
                </div>
            }
        }))
        setIsFetching(false)
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div className="page-content">
            <ConfirmModal active={confirmModal} setActive={setConfirmModal} callback={deleteRole}/>
            <Modal className="modal-dialog-centered" isOpen={permissionForm?.status}
                   toggle={() => setPermissionForm({})}>
                <ModalHeader
                    toggle={() => setPermissionForm({})}>{`${permissionForm?.data ? 'Səlahiyyətə düzəliş et' : 'Səlahiyyət əlavə et'}`}</ModalHeader>
                <AddPermission fetchData={fetchData} permissionForm={permissionForm}
                               setPermissionForm={setPermissionForm}/>
            </Modal>
            <div className="container-fluid">
                <Breadcrumbs breadcrumbItem={`SƏLAHİYYƏTLƏR (${data?.length})`}/>
                <Row>
                    <Col sm={12}>
                        <Card>
                            <CardBody>
                                <Can moduleName={modules.permissions.value} keys={['can_add']}>
                                    <Button
                                        onClick={() => setPermissionForm({status: true})}
                                        type="button"
                                        color="success"
                                        className="btn-label mb-3"
                                    >
                                        <i className="bx bx-plus label-icon"/> Əlavə et
                                    </Button>
                                </Can>
                                <div className="table-responsive">
                                    {isFetching ? (
                                        Array.from({length: 10}).map((item, index) => (
                                            <p className="placeholder-glow mb-2" key={index}>
                                                <span className="placeholder py-4 col-12"/>
                                            </p>
                                        ))
                                    ) : (
                                        <table className="table table-bordered">
                                            <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Ad</th>
                                                <th>Yaradılma tarixi</th>
                                                <th>Yenilənmə tarixi</th>
                                                <th>Əməliyyatlar</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {data.map(item => (
                                                <tr key={item.id}>
                                                    <td>{item.index}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.createdAt}</td>
                                                    <td>{item.updatedAt}</td>
                                                    <td>{item.actions}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Permissions
