import {
    AccordionBody,
    AccordionHeader,
    Badge,
    Button,
    Card,
    CardBody,
    Col,
    Input,
    Label,
    ModalBody,
    Row,
    Spinner,
    UncontrolledAccordion
} from "reactstrap";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import Form from "../../helpers/form";
import educationTypes from '../../data/educationTypes.json'
import degrees from '../../data/degrees.json'
import documentStatuses from '../../data/documentStatuses.json'
import contractStatuses from '../../data/contractStatuses.json'
import missingFiles from '../../data/missingFiles.json'
import visa from '../../data/visa.json'
import Select from "react-select";
import {useEffect, useState} from "react";
import Contract from "../../api/contract";
import moment from "moment";
import PDFMerger from 'pdf-merger-js/browser';
import Flatpickr from "react-flatpickr";
import 'flatpickr/dist/l10n/az.js';
import User from "../../helpers/user";
import Fancybox from '../../components/Common/FancyBox';
import Helpler from "../../helpers/Helpler";

const AddContract = ({setModal, modal, fetchData}) => {
    const id = modal?.data
    const [loader, setLoader] = useState(false)
    const [downloadLoader, setDownloadLoader] = useState(false)
    const [deleteFileLoader, setDeleteFileLoader] = useState({})
    const [logs, setLogs] = useState([])
    const [previousFiles, setPreviousFiles] = useState([])
    const {control, handleSubmit, setValue, setError, watch, getValues, formState: {errors}} = useForm({
        defaultValues: {
            contractDate: new Date(),
            educationType: educationTypes.full_time_education,
            degree: degrees.bachelors,
            documentStatus: documentStatuses.in_progress,
            status: contractStatuses.in_progress,
            files: [{
                file: null,
                description: ''
            }],
        }
    })

    const {fields, append} = useFieldArray({
        control,
        name: 'files'
    })

    const status = watch('status')?.value
    const code = watch('code')?.split('-')?.[0]

    const submit = async (values) => {
        setLoader(true)
        try {
            const formData = new FormData()
            const data = {
                ...values,
                contractDate: Form.convertFormDate(values?.contractDate),
                birthDate: Form.convertFormDate(values?.birthDate),
                educationType: values?.educationType?.value,
                documentStatus: values?.documentStatus?.value,
                visa: values?.visa?.value,
                status: values?.status?.value,
                degree: values?.degree?.value,
            }

            delete data.files
            delete data.missingFiles

            Object.keys(data).forEach(item => {
                if (![null, undefined, ''].includes(data[item])) {
                    formData.append(item, data[item])
                }
            })

            values?.missingFiles?.forEach(item => {
                formData.append('missingFiles', item?.value)
            })

            if (values?.files?.some(item => item?.file && item?.description)) {
                values?.files?.forEach((item) => {
                    if (item?.file && item?.description) {
                        formData.append('files', item?.file)
                        formData.append('filesDescription', item?.description)
                    }
                })
            }

            await Contract[id ? 'update' : 'create'](formData, id)
            if (id) {
                setValue('log', '')
                setValue('files', [{
                    file: null,
                    description: ''
                }])
                fetchContract()
            } else {
                setModal({})
            }
            fetchData(false)
        } catch (e) {
            Form.setApiErrors(e.response.data, setError)
        } finally {
            setLoader(false)
        }
    }

    const fetchContract = async () => {
        const {data} = await Contract.getById(id)
        setLogs(data?.log_ids)
        setPreviousFiles(data?.files)
        setValue('code', data?.code)
        setValue('contractDate', data?.contractDate ? data?.contractDate : null)
        setValue('price', data?.price)
        setValue('fullName', data?.fullName)
        setValue('educationType', Object.values(educationTypes).find(item => item.value === data?.educationType))
        setValue('birthDate', data?.birthDate ? data?.birthDate : null)
        setValue('documentStatus', Object.values(documentStatuses).find(item => item.value === data?.documentStatus))
        setValue('status', Object.values(contractStatuses).find(item => item.value === data?.status))
        setValue('sendedDocumentAddress', data?.sendedDocumentAddress)
        setValue('passportNumber', data?.passportNumber)
        setValue('fatherName', data?.fatherName)
        setValue('motherName', data?.motherName)
        setValue('degree', Object.values(degrees).find(item => item.value === data?.degree))
        setValue('visa', visa.find(item => item.value === data?.visa))
        setValue('country', data?.country)
        setValue('university', data?.university)
        setValue('specialty', data?.specialty)
        setValue('phone', data?.phone)
        setValue('note', data?.note)
        setValue('missingFiles', data?.missingFiles?.map(item => {
            return {value: item, label: item}
        }))
    }

    const checkPrevious = async (index) => {
        const data = getValues().files
        if (data[index].file && data[index].description && !data?.[index + 1]) {
            append({
                file: null,
                description: ''
            })
        }
    }

    const generateMergedFiles = async () => {
        setDownloadLoader(true)
        const merger = new PDFMerger();
        for (const file of previousFiles) {
            if (file?.file?.endsWith('.pdf')) {
                await merger.add(Form.generateFileUrl(file.file))
            } else if (file?.file?.match(/\.(jpg|jpeg|png|gif|bmp)$/i)) {
                await merger.add(await Helpler.createPDFFromImage(Form.generateFileUrl(file.file)))
            }
        }
        const contract = getValues()
        await merger.save(contract?.fullName);
        setDownloadLoader(false)
    }

    const downloadPdf = async item => {
        const merger = new PDFMerger();
        await merger.add(Form.generateFileUrl(item.file))
        await merger.save(item.description);
    }

    const deleteFile = async (fileId) => {
        setDeleteFileLoader(prev => ({
            ...prev,
            [fileId]: true
        }))
        await Contract.deleteFile(id, {fileId})
        fetchContract()
        setDeleteFileLoader(prev => ({
            ...prev,
            [fileId]: false
        }))
    }

    useEffect(() => {
        if (id) {
            fetchContract()
        }
    }, [id])

    return (
        <form onSubmit={handleSubmit(submit)}>
            <ModalBody className="bg-light">
                <Row>
                    <Col sm={12} md={6}>
                        <div className="d-flex flex-column">
                            <Card>
                                <CardBody>
                                    <div className="mb-3">
                                        <Label for="fullName">Ad soyad</Label>
                                        <Controller
                                            rules={{required: true}}
                                            render={({field: {value, onChange}}) => (
                                                <Input
                                                    name="fullName"
                                                    value={value}
                                                    onChange={onChange}
                                                    invalid={errors?.fullName}
                                                />
                                            )} name="fullName" control={control}/>
                                        {Form.generateFormFeedback(errors, 'fullName', errors?.fullName?.message)}
                                    </div>
                                    <div className="mb-3">
                                        <Label for="birthDate">Doğum tarixi</Label>
                                        <Controller
                                            render={({field: {value, onChange}}) => (
                                                <Flatpickr
                                                    className="form-control d-block"
                                                    value={value}
                                                    onChange={onChange}
                                                    options={{
                                                        locale: 'az'
                                                    }}
                                                />
                                            )} name="birthDate" control={control}/>
                                    </div>
                                    <div className="mb-3">
                                        <Label for="passportNumber">Passport nömərsi</Label>
                                        <Controller
                                            render={({field: {value, onChange}}) => (
                                                <Input
                                                    name="passportNumber"
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            )} name="passportNumber" control={control}/>
                                    </div>
                                    <div className="mb-3">
                                        <Label for="fatherName">Ata adı</Label>
                                        <Controller
                                            render={({field: {value, onChange}}) => (
                                                <Input
                                                    name="fatherName"
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            )} name="fatherName" control={control}/>
                                    </div>
                                    <div className="mb-3">
                                        <Label for="motherName">Ana adı</Label>
                                        <Controller
                                            render={({field: {value, onChange}}) => (
                                                <Input
                                                    name="motherName"
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            )} name="motherName" control={control}/>
                                    </div>
                                    <div className="mb-3">
                                        <Label for="phone">Telefon</Label>
                                        <Controller
                                            render={({field: {value, onChange}}) => (
                                                <Input
                                                    name="phone"
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            )} name="phone" control={control}/>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <Button type="submit" color="primary" disabled={loader}>
                                            {loader ?
                                                <Spinner size="sm" color="light"/> : (id ? 'Yenilə' : 'Əlavə et')}
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <div className="mb-3">
                                        <Label for="code">Müqavilə kodu</Label>
                                        <Controller
                                          rules={{ required: true }}
                                          render={({ field: { value, onChange } }) => (
                                            <Input
                                              value={value}
                                              name="code"
                                              onChange={onChange}
                                              invalid={errors?.code}
                                            />
                                          )} name="code" control={control} />
                                        {Form.generateFormFeedback(errors, "code", errors?.code?.message)}
                                    </div>
                                    <div className="mb-3">
                                        <Label for="contractDate">Tarix</Label>
                                        <Controller
                                          render={({ field: { value, onChange } }) => (
                                            <Flatpickr
                                              className="form-control d-block"
                                              value={value}
                                              onChange={onChange}
                                              options={{
                                                  locale: "az"
                                              }}
                                            />
                                          )} name="contractDate" control={control} />
                                    </div>
                                    <div className="mb-3">
                                        <Label for="price">Müqavilə dəyəri</Label>
                                        <Controller
                                          rules={{ required: true }}
                                          render={({ field: { value, onChange } }) => (
                                            <Input
                                              name="price"
                                              value={value}
                                              type="number"
                                              onChange={onChange}
                                              invalid={errors?.price}
                                            />
                                          )} name="price" control={control} />
                                        {Form.generateFormFeedback(errors, "price", errors?.price?.message)}
                                    </div>
                                    <div className="mb-3">
                                        <Label for="documentStatus">Viza tələbi</Label>
                                        <Controller
                                          rules={{ required: true }}
                                          render={({ field: { value, onChange } }) => (
                                            <Select
                                              name="visa"
                                              options={visa}
                                              value={value}
                                              onChange={onChange}
                                              className={errors?.visa && 'is-invalid'}
                                            />
                                          )} name="visa" control={control} />
                                        {Form.generateFormFeedback(errors, "visa", errors?.visa?.message)}
                                    </div>
                                    <div className="mb-3">
                                        <Label for="documentStatus">Sənədlərin statusu</Label>
                                        <Controller
                                          render={({ field: { value, onChange } }) => (
                                            <Select
                                              name="documentStatus"
                                              options={Object.values(documentStatuses)}
                                              value={value}
                                              onChange={onChange}
                                            />
                                          )} name="documentStatus" control={control} />
                                    </div>
                                    <div className="mb-3">
                                        <Label for="status">Qəbul prosesi</Label>
                                        <Controller
                                          render={({ field: { value, onChange } }) => (
                                            <Select
                                              name="status"
                                              options={Object.values(contractStatuses)}
                                              value={value}
                                              onChange={onChange}
                                            />
                                          )} name="status" control={control} />
                                    </div>
                                    {status === contractStatuses.documentSended.value && User.isSuperAdmin() && (
                                      <div className="mb-3">
                                          <Label for="sendedDocumentAddress">Sənədlər kimə/hara
                                              göndərildi</Label>
                                          <Controller
                                            render={({ field: { value, onChange } }) => (
                                              <Input
                                                name="sendedDocumentAddress"
                                                id="sendedDocumentAddress"
                                                value={value}
                                                onChange={onChange}
                                              />
                                            )} name="sendedDocumentAddress" control={control} />
                                      </div>
                                    )}
                                    <div className="mb-3">
                                        <Label for="educationType">Əyani/Qiaybi/Distant</Label>
                                        <Controller
                                          render={({ field: { value, onChange } }) => (
                                            <Select
                                              name="educationType"
                                              options={Object.values(educationTypes)}
                                              value={value}
                                              onChange={onChange}
                                            />
                                          )} name="educationType" control={control} />
                                    </div>
                                    <div className="mb-3">
                                        <Label for="degree">Dərəcə</Label>
                                        <Controller
                                          render={({ field: { value, onChange } }) => (
                                            <Select
                                              name="degree"
                                              options={Object.values(degrees)}
                                              value={value}
                                              onChange={onChange}
                                            />
                                          )} name="degree" control={control} />
                                    </div>
                                    <div className="mb-3">
                                        <Label for="country">Ölkə</Label>
                                        <Controller
                                          render={({ field: { value, onChange } }) => (
                                            <Input
                                              name="country"
                                              value={value}
                                              onChange={onChange}
                                            />
                                          )} name="country" control={control} />
                                    </div>
                                    <div className="mb-3">
                                        <Label for="university">Universitetlər</Label>
                                        <Controller
                                          render={({ field: { value, onChange } }) => (
                                            <Input
                                              name="university"
                                              value={value}
                                              onChange={onChange}
                                            />
                                          )} name="university" control={control} />
                                    </div>
                                    <div className="mb-3">
                                        <Label for="specialty">İxtisaslar</Label>
                                        <Controller
                                          render={({ field: { value, onChange } }) => (
                                            <Input
                                              name="specialty"
                                              value={value}
                                              onChange={onChange}
                                            />
                                          )} name="specialty" control={control} />
                                    </div>
                                    <div className="mb-3">
                                        <Label for="note">Qeyd</Label>
                                        <Controller
                                          render={({ field: { value, onChange } }) => (
                                            <Input
                                              name="note"
                                              type="textarea"
                                              rows={5}
                                              value={value}
                                              onChange={onChange}
                                            />
                                          )} name="note" control={control} />
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <Button type="submit" color="primary" disabled={loader}>
                                            {loader ?
                                              <Spinner size="sm" color="light" /> : (id ? "Yenilə" : "Əlavə et")}
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                    <Col sm={12} md={6}>
                        <div className="d-flex flex-column">
                            <Card>
                                <CardBody className="overflow-auto" style={{ maxHeight: "500px" }}>
                                    <div className="mb-3">
                                        <Label for="log">Tarixçə</Label>
                                        <Controller
                                          render={({ field: { value, onChange } }) => (
                                            <Input
                                              name="log"
                                              type="textarea"
                                              rows={5}
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            )} name="log" control={control}/>
                                    </div>
                                    <div className="d-flex justify-content-end mb-3">
                                        <Button type="submit" color="primary" disabled={loader}>
                                            {loader ? <Spinner size="sm" color="light"/> : 'Əlavə et'}
                                        </Button>
                                    </div>
                                    {logs?.map((item, index) => (
                                        <div key={item.id}>
                                            <p className="mb-0  font-size-14 d-flex justify-content-between">
                                                <span
                                                    className="fw-bold">{index + 1}. {`${item?.user_id?.name} ${item?.user_id?.surname}`}</span>
                                                <span>{moment(item?.createdAt).format('DD.MM.YYYY | HH:mm')}</span>
                                            </p>
                                            <p className="text-secondary">{item?.log}</p>
                                        </div>
                                    ))}
                                </CardBody>
                            </Card>
                            <Card>
                                <UncontrolledAccordion>
                                    <AccordionHeader targetId="files">
                                        <p className="fw-bold mb-0">Fayllar</p>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="files">
                                        <CardBody>
                                            {fields.map((field, index) => (
                                                <div className="mb-3" key={field.id}>
                                                    <Controller render={() => (
                                                        <Input
                                                            className="form-control mb-3"
                                                            onChange={e => {
                                                                setValue(`files[${index}].file`, e.target.files[0])
                                                                checkPrevious(index, e.target.files[0])
                                                            }}
                                                            type="file" accept=".pdf,.png,.jpeg,.jpg"/>
                                                    )} name={`files.${index}.file`} control={control}/>
                                                    <Controller render={({field: {onChange}}) => (
                                                        <Input
                                                            className="form-control"
                                                            placeholder="Açıqlama"
                                                            onChange={e => {
                                                                onChange(e)
                                                                checkPrevious(index)
                                                            }}
                                                        />
                                                    )} name={`files.${index}.description`} control={control}/>
                                                </div>
                                            ))}
                                            <div className="d-flex justify-content-end mb-3">
                                                <Button type="submit" color="primary" disabled={loader}>
                                                    {loader ? <Spinner size="sm" color="light"/> : 'Yüklə'}
                                                </Button>
                                            </div>
                                            {previousFiles?.length > 0 && (
                                                <div className="table-responsive">
                                                    <table className="table">
                                                        <thead>
                                                        <tr>
                                                            <th>Açıqlama</th>
                                                            <th>Ölçü</th>
                                                            <th>
                                                                <div className="d-flex gap-1">
                                                                    <Button color="primary"
                                                                            disabled={downloadLoader || previousFiles?.length < 2}
                                                                            onClick={generateMergedFiles}
                                                                            size="sm">
                                                                        {downloadLoader ?
                                                                            <Spinner size="sm" color="light"/> :
                                                                            <i
                                                                                className="bx bx-download"/>}
                                                                    </Button>
                                                                    <Button color="warning"
                                                                            tag="a"
                                                                            target="_blank"
                                                                            href={`/student/${id}/${Form.createSlug(getValues()?.fullName)}`}
                                                                            size="sm">
                                                                        <i className="bx bx-info-circle"/>
                                                                    </Button>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {previousFiles?.map((item) => (
                                                            <tr key={item.id}>
                                                                <td>{item.description}</td>
                                                                <td>{Form.formatFileSize(item.size)}</td>
                                                                <td>
                                                                    <div className="d-flex gap-1">
                                                                        {item?.file?.includes('pdf') && (
                                                                            <Button color="success"
                                                                                    type="button"
                                                                                    onClick={() => downloadPdf(item)}
                                                                                    size="sm"><i
                                                                                className="bx bx-download"/></Button>
                                                                        )}
                                                                        <a target="_blank"
                                                                           href={Form.generateFileUrl(item?.file)}
                                                                           className="btn btn-sm btn-primary">
                                                                            {downloadLoader ?
                                                                                <Spinner size="sm" color="light"/> :
                                                                                <i
                                                                                    className="bx bx-show"/>}
                                                                        </a>
                                                                        <Button color="danger"
                                                                                type="button"
                                                                                disabled={deleteFileLoader?.[item.id]}
                                                                                onClick={() => deleteFile(item.id)}
                                                                                size="sm">
                                                                            {deleteFileLoader?.[item.id] ?
                                                                                <Spinner size="sm" color="light"/> : <i
                                                                                    className="bx bx-trash"/>}
                                                                        </Button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        <tr>
                                                            <td/>
                                                            <td>
                                                                <Badge color="success">
                                                                    {Form.formatFileSize(previousFiles?.map(item => item?.size).reduce((accumullator, currentValue) => accumullator + currentValue))}
                                                                </Badge>
                                                            </td>
                                                            <td/>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}
                                            <Fancybox
                                                options={{
                                                    Carousel: {
                                                        infinite: false,
                                                    },
                                                }}
                                            >
                                                {previousFiles?.map(item => (
                                                    !item?.file?.includes('pdf') && (
                                                        <a key={item.id} data-fancybox="gallery"
                                                           href={Form.generateFileUrl(item?.file)}>
                                                            <img
                                                                src={Form.generateFileUrl(item?.file)}
                                                                alt={"project"}
                                                                width={50}
                                                                height={50}
                                                                className="clickable-image"
                                                                style={{cursor: "pointer", objectFit: 'contain'}}
                                                            />
                                                        </a>
                                                    )
                                                ))}
                                            </Fancybox>
                                        </CardBody>
                                    </AccordionBody>
                                </UncontrolledAccordion>
                            </Card>
                            <Card>
                                <CardBody>
                                    <Label>Əksik fayllar</Label>
                                    <Controller render={({field: {value, onChange}}) => (
                                        <Select
                                            isDisabled={!code}
                                            value={value}
                                            isMulti
                                            onChange={onChange}
                                            options={code && missingFiles?.[code?.toLowerCase() === 'ru' ? 'ru' : 'tr']?.map(item => {
                                                return {value: item, label: item}
                                            })}/>
                                    )} name="missingFiles" control={control}/>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
        </form>
    )
}

export default AddContract
