import React from "react"

import {Card, CardBody, Col, Row} from "reactstrap"
import {Link} from "react-router-dom"
import profileImg from "../../assets/images/profile-img.png"
import LocalStorageManager from "../../helpers/localStorageManager";
import roles from '../../data/roles.json'
import Form from "../../helpers/form";
import User from "../../helpers/user";

const WelcomeComp = () => {
    const user = LocalStorageManager.get('user')
    return (
        <React.Fragment>
            <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                    <Row>
                        <Col xs="7">
                            <div className="text-primary p-3">
                                <h5 className="text-primary">Xoş gəlmisən !</h5>
                                <p>Azstudy</p>
                            </div>
                        </Col>
                        <Col xs="5" className="align-self-end">
                            <img src={profileImg} alt="" className="img-fluid"/>
                        </Col>
                    </Row>
                </div>
                <CardBody className="pt-0">
                    <Row>
                        <Col sm="6">
                            {user?.profile_image ? (
                                <div className="avatar-md profile-user-wid mb-4">
                                    <img
                                        className="rounded-circle img-thumbnail"
                                        src={Form.generateFileUrl(user?.profile_image)}
                                        alt="Header Avatar"
                                    />
                                </div>

                            ) : (
                                <span className="avatar-md header-profile-user p-2">
                                {User.getFirstChars(user)}
                                    </span>
                            )}
                            <h5 className="font-size-15 text-truncate">{user?.name} {user?.surname}</h5>
                            <p className="text-muted mb-0 text-truncate">{Object.values(roles).find(item => item.value === user?.role_id)?.label}</p>
                        </Col>

                        <Col sm="6">
                            <div className="pt-4">
                                <div className="mt-4">
                                    <Link
                                        to="/profile"
                                        className="btn btn-primary  btn-sm"
                                    >
                                        Profil <i className="mdi mdi-arrow-right ms-1"/>
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}
export default WelcomeComp
