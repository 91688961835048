import axios from './index'

class Statistics {
    getDashboard = (params) => axios.get('statistics/dashboard', {params})
    getAppealsByStatus = (params) => axios.get('statistics/appeals/status', {params})
    getAppealStatisticsByTime = (params) => axios.get('statistics/appeals/time', {params})
    getContractStatisticsByDegree = (params) => axios.get('statistics/contracts/degree', {params})
    exportAppealStatus = (params) => axios.get('statistics/export/appeals/status', {params})
    exportContractDegree = (params) => axios.get('statistics/export/contracts/degree', {params})
}

export default new Statistics()
