import {Button, Input, Label, ModalBody, ModalFooter, Spinner} from "reactstrap";
import {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import FormHelper from "../../helpers/form";
import Auth from "../../api/auth";

const AddModal = ({fetchData, setModal}) => {
    const [loader, setLoader] = useState(false)

    const {handleSubmit, control, setError, formState: {errors}} = useForm()

    const submit = async (values) => {
        setLoader(true)
        try {
            await Auth.register({
                ...values,
                fromAdmin: true
            })
            fetchData()
            setLoader(false)
            setModal(false)
        } catch (e) {
            FormHelper.setApiErrors(e.response.data, setError)
        } finally {
            setLoader(false)
        }
    }

    return (
        <form onSubmit={handleSubmit(submit)}>
            <ModalBody>
                <div className="mb-3">
                    <Label className="form-label">Ad</Label>
                    <Controller rules={{
                        required: true
                    }} render={({field: {value, onChange}}) => (
                        <Input
                            name="name"
                            className="form-control"
                            type="text"
                            onChange={onChange}
                            value={value}
                            invalid={errors?.name}
                        />
                    )} name="name" control={control}/>
                    {FormHelper.generateFormFeedback(errors, 'name', errors?.name?.message)}
                </div>
                <div className="mb-3">
                    <Label className="form-label">Soyad</Label>
                    <Controller rules={{
                        required: true
                    }} render={({field: {value, onChange}}) => (
                        <Input
                            name="surname"
                            className="form-control"
                            type="text"
                            onChange={onChange}
                            value={value}
                            invalid={errors?.surname}
                        />
                    )} name="surname" control={control}/>
                    {FormHelper.generateFormFeedback(errors, 'surname', errors?.surname?.message)}
                </div>
                <div className="mb-3">
                    <Label className="form-label">Email</Label>
                    <Controller rules={{
                        required: true
                    }} render={({field: {value, onChange}}) => (
                        <Input
                            name="email"
                            className="form-control"
                            type="email"
                            onChange={onChange}
                            value={value}
                            invalid={errors?.email}
                        />
                    )} name="email" control={control}/>
                    {FormHelper.generateFormFeedback(errors, 'email', errors?.email?.message)}
                </div>
                <div className="mb-3">
                    <Label className="form-label">Şifrə</Label>
                    <Controller rules={{
                        required: true
                    }} render={({field: {value, onChange}}) => (
                        <Input
                            name="password"
                            className="form-control"
                            type="password"
                            onChange={onChange}
                            value={value}
                            invalid={errors?.password}
                        />
                    )} name="password" control={control}/>
                    {FormHelper.generateFormFeedback(errors, 'password', errors?.password?.message)}
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-end gap-1">
                    <Button outline type="button" onClick={() => setModal(false)} color="secondary">Bağla</Button>
                    <Button disabled={loader} type="submit" color="primary">
                        {loader ? <Spinner color="light" size="sm"/> : 'Yadda saxla'}
                    </Button>
                </div>
            </ModalFooter>
        </form>
    )
}

export default AddModal
