import roles from "../data/roles.json"
import LocalStorageManager from "./localStorageManager";


class User {
    isSuperAdmin = () => {
        const userRole = LocalStorageManager.get('user')?.role_id
        return roles.superAdmin.value === userRole
    }

    isAdmin = () => {
        const userRole = LocalStorageManager.get('user')?.role_id
        return [roles.superAdmin.value, roles.admin.value].includes(userRole)
    }

    isEducationExpert = () => {
        const userRole = LocalStorageManager.get('user')?.role_id
        return roles.educationExpert.value === userRole
    }

    isDocumentExpert = () => {
        const userRole = LocalStorageManager.get('user')?.role_id
        return roles.documentBranchExpert.value === userRole
    }

    isAssistant = () => {
        const userRole = LocalStorageManager.get('user')?.role_id
        return roles.assistant.value === userRole
    }

    isReception = () => {
        const userRole = LocalStorageManager.get('user')?.role_id
        return roles.reception.value === userRole
    }

    getAuthUser = () => {
        return LocalStorageManager.get('user')
    }

    getUserRole = (user) => {
        return Object.values(roles).find(item => item.value === user?.role_id)?.label
    }

    getFirstChars = (user) => {
        return `${user?.name?.[0]?.toUpperCase()}${user?.surname?.[0]?.toUpperCase()}`
    }
}

export default new User()
