import "./index.scss"
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Fragment, useEffect, useState} from "react";
import {Button, Modal, ModalHeader, UncontrolledTooltip} from "reactstrap";
import TaskGroups from "../../api/task-groups";
import GroupModal from "./GroupModal";
import User from "../../helpers/user";
import ConfirmModal from "../../components/Common/ConfirmModal";
import Task from "../../api/tasks";
import TaskModal from "./TaskModal";
import Auth from "../../api/auth";
import Form from "../../helpers/form";
import {Link} from "react-router-dom";
import Group from "./Group";

const Tasks = () => {
    document.title = 'Tapşırıqlar | Azstudy'

    const [groups, setGroups] = useState([])
    const [isFetching, setIsFetching] = useState(false)
    const [groupModal, setGroupModal] = useState({})
    const [taskModal, setTaskModal] = useState({})
    const [confirmModal, setConfirmModal] = useState(false)
    const [taskConfirmModal, setTaskConfirmModal] = useState(false)
    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)

    const fetchGroups = async (userId) => {
        const {data} = await TaskGroups.get({userId})
        setGroups(data)
    }


    const deleteGroup = async () => {
        await TaskGroups.delete(confirmModal)
        fetchGroups()
    }

    const fetchData = async () => {
        setIsFetching(true)
        await fetchGroups()
        setIsFetching(false)
    }

    const deleteTask = async () => {
        await Task.delete(taskConfirmModal)
        fetchGroups()
    }

    const fetchUsers = async () => {
        const {data} = await Auth.getActiveUsers()
        setUsers(Form.formatUsers(data))
    }

    const avatarColors = ['dark', 'primary', 'success', 'warning', 'danger', 'secondary', 'info', 'dark', 'primary', 'success', 'warning', 'danger', 'secondary', 'info', 'dark', 'primary', 'success', 'warning', 'danger', 'secondary', 'info', 'dark', 'primary', 'success', 'warning', 'danger', 'secondary', 'info']

    const handleChangeUser = async id => {
        setIsFetching(true)
        if (selectedUser === id) {
            setSelectedUser(null)
            await fetchGroups()
            setIsFetching(false)
            return
        }
        setSelectedUser(id)
        await fetchGroups(id)
        setIsFetching(false)
    }

    useEffect(() => {
        if (User.isAdmin() || User.isSuperAdmin()) {
            fetchUsers()
        }
        fetchData()
    }, [])

    return (
        <div className="page-content tasks-page">
            <ConfirmModal active={confirmModal} setActive={setConfirmModal} callback={deleteGroup}/>
            <ConfirmModal active={taskConfirmModal} setActive={setTaskConfirmModal} callback={deleteTask}/>
            <Modal className="modal-dialog-centered" isOpen={groupModal?.status}
                   toggle={() => setGroupModal({})}>
                <ModalHeader
                    toggle={() => setGroupModal({})}>{`${groupModal?.data ? 'Qrupa düzəliş et' : 'Qrup əlavə et'}`}</ModalHeader>
                <GroupModal fetchData={fetchGroups} form={groupModal}
                            setForm={setGroupModal}/>
            </Modal>
            <Modal className="modal-dialog-centered" size="lg" isOpen={taskModal?.status}
                   toggle={() => setTaskModal({})}>
                <ModalHeader
                    toggle={() => setTaskModal({})}>{`${taskModal?.data?.id ? 'Tapşırığa düzəliş et' : 'Tapşırıq əlavə et'}`}</ModalHeader>
                <TaskModal selectedUser={selectedUser} groups={groups} fetchData={fetchGroups} form={taskModal}
                           setForm={setTaskModal}/>
            </Modal>
            <div className="container-fluid">
                <div className="d-flex flex-column flex-md-row align-items-start justify-content-between">
                    <Breadcrumbs
                        breadcrumbItem={`TAPŞIRIQLAR`}/>
                    {(User.isAdmin() || User.isSuperAdmin()) && (
                        <Button color="success" onClick={() => setGroupModal({status: true})}>
                            Qrup əlavə et
                        </Button>
                    )}
                </div>
                {(User.isAdmin() || User.isSuperAdmin()) && (
                    <div className="d-flex mt-3 justify-content-center">
                        <div className="avatar-group">
                            {users?.map((item, index) => (
                                <Fragment key={item.id}>
                                    <div onClick={() => handleChangeUser(item.id)}
                                         className={`avatar-group-item ${selectedUser === item.id ? 'active' : ''}`}
                                         id={`user-${item.id}`}>
                                        <Link to="#" className="d-inline-block">
                                            {item?.profile_image ? (
                                                <img src={Form.generateFileUrl(item.profile_image)} alt={item.name}
                                                     className="rounded-circle avatar-sm"/>
                                            ) : (
                                                <div className="avatar-sm">
                                            <span
                                                className={`avatar-title rounded-circle bg-${avatarColors[index]} text-white font-size-16`}>
                                            {User.getFirstChars(item)}
                                        </span>
                                                </div>
                                            )}
                                        </Link>
                                    </div>
                                    <UncontrolledTooltip target={`user-${item.id}`} placement="bottom">
                                        {item.name} {item.surname}
                                    </UncontrolledTooltip>
                                </Fragment>
                            ))}
                        </div>
                    </div>
                )}
                <div className="task-cards mt-3">
                    {isFetching ? (
                        Array.from({length: 5}).map((item, index) => (
                            <p className="placeholder-glow mb-2" key={index}>
                                <span className="placeholder py-4 col-12"/>
                            </p>
                        ))
                    ) : (
                        groups.map(item => (
                            <Group
                                key={item.id}
                                groups={groups}
                                item={item}
                                setGroupModal={setGroupModal}
                                setTaskModal={setTaskModal}
                                setConfirmModal={setConfirmModal}
                                setTaskConfirmModal={setTaskConfirmModal}
                            />
                        ))
                    )}
                </div>
            </div>
        </div>
    )
}

export default Tasks
