import PropTypes from 'prop-types';
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {Route, Routes} from "react-router-dom";
import {layoutTypes} from "./constants/layout";
import {authProtectedRoutes, publicRoutes} from "./routes";
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import "flatpickr/dist/themes/material_blue.css";
import Authmiddleware from "./routes/route";
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import "./assets/scss/theme.scss";
import './index.scss'

const getLayout = (layoutType) => {
    let Layout = VerticalLayout;
    switch (layoutType) {
        case layoutTypes.VERTICAL:
            Layout = VerticalLayout;
            break;
        case layoutTypes.HORIZONTAL:
            Layout = HorizontalLayout;
            break;
        default:
            break;
    }
    return Layout;
};

const App = () => {

    const {layoutType} = useSelector((state) => ({
        layoutType: state.Layout.layoutType,
    }));

    const Layout = getLayout(layoutType);

    useEffect(() => {
        const inputs = document.querySelectorAll('input[type="number"]')
        inputs.forEach(item => {
            item.addEventListener('mousewheel', e => {
                e.preventDefault()
            })
        })
    }, [])

    return (
        <React.Fragment>
            <ToastContainer/>
            <Routes>
                {publicRoutes.map((route, idx) => (
                    <Route
                        path={route.path}
                        element={
                            <NonAuthLayout>
                                {route.component}
                            </NonAuthLayout>
                        }
                        key={idx}
                        exact={true}
                    />
                ))}

                {authProtectedRoutes.map((route, idx) => (
                    <Route
                        path={route.path}
                        element={
                            <Authmiddleware name={route?.name}>
                                <Layout>{route.component}</Layout>
                            </Authmiddleware>}
                        key={idx}
                        exact={true}
                    />
                ))}
            </Routes>
        </React.Fragment>
    );
};

App.propTypes = {
    layout: PropTypes.any
};

export default App;
