import {Card, CardBody, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown} from "reactstrap";
import {Link} from "react-router-dom";
import statisticsFilter from "../../../data/statisticsFilter.json"
import {useEffect, useState} from "react";
import classNames from 'classnames'
import ReactApexChart from "react-apexcharts";
import Statistics from "../../../api/statistics";
import PDFMerger from "pdf-merger-js/browser";
import Form from "../../../helpers/form";

const Degree = () => {
    const [period, setPeriod] = useState(statisticsFilter.day.value)
    const [downloadLoader, setDownloadLoader] = useState(false)
    const [data, setData] = useState([])
    const [isFetching, setIsFetching] = useState(true)
    const [total, setTotal] = useState(0)
    const series = data?.map(item => item?.value);
    const options = {
        labels: data?.map(item => item?.label),
        colors: ['#037ef3','#ff6c5f', '#009f4d'],
        legend: {show: true},
        plotOptions: {
            pie: {
                donut: {
                    size: "70%",
                },
            },
        },
    };

    const fetchData = async () => {
        setIsFetching(true)
        const {data} = await Statistics.getContractStatisticsByDegree({
            period
        })
        setData(data)
        setTotal(data?.reduce((accumlator, value) => {
            return accumlator + value.value
        }, 0))
        setIsFetching(false)
    }

    const downloadPdf = async item => {
        const merger = new PDFMerger();
        await merger.add(Form.generateGeneratedFileUrl(item))
        await merger.save(`Müqavilələr Hesabat (Dərəcəyə görə)(${statisticsFilter[period].label})`);
    }

    const exportData = async (as) => {
        setDownloadLoader(true)
        const data = await Statistics.exportContractDegree({as, period})
        const contentType = as === 'csv' ? 'text/csv' : 'application/pdf'
        const file = new Blob([data], {type: contentType})
        if (as === 'csv') {
            const a = document.createElement('a');
            a.download = `Müqavilələr Hesabat (Dərəcəyə görə)(${statisticsFilter[period].label}).${as}`;
            a.href = window.URL.createObjectURL(file);
            a.className = 'd-none'
            a.dataset.downloadurl = [contentType, a.download, a.href].join(':');
            a.click()
            document.body.appendChild(a);
        } else {
            downloadPdf(data?.data)
        }

        setDownloadLoader(false)
    }

    useEffect(() => {
        fetchData()
    }, [period])

    return (
        <Card>
            <CardBody>
                <div className="d-sm-flex align-items-center flex-wrap">
                    <h4 className="card-title">Dərəcə üzrə ({total})</h4>
                    <div className="ms-auto d-flex gap-1">
                        <ul className="nav nav-pills">
                            {Object.values(statisticsFilter).map(item => (
                                <li className="nav-item" key={item.value}>
                                    <Link
                                        to="#"
                                        className={classNames(
                                            {
                                                active: period === item.value
                                            },
                                            "nav-link"
                                        )}
                                        onClick={() => setPeriod(item.value)}
                                    >
                                        {item.label}
                                    </Link>{" "}
                                </li>
                            ))}
                        </ul>
                        <UncontrolledDropdown>
                            <DropdownToggle className="btn btn-success"
                                            disabled={downloadLoader}>Yüklə</DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => exportData('csv')}>EXCEL</DropdownItem>
                                <DropdownItem onClick={() => exportData('pdf')}>PDF</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>
                {isFetching ? (
                    <div className="d-flex justify-content-center align-items-center p-5">
                        <Spinner size="lg" color="primary"/>
                    </div>
                ) : (
                    <div className="donut-chart">
                        <ReactApexChart
                            options={options}
                            series={series}
                            type="donut"
                            height={320}
                            className="apex-charts"
                        />
                    </div>
                )}
            </CardBody>
        </Card>
    )
}

export default Degree
