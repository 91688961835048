import {FormFeedback} from "reactstrap";
import React from 'react'
import {toast} from "react-toastify";
import moment from "moment";

const setApiErrors = (data, setError) => {
    if (!data?.fields) {
        toast.error(data?.message)
        return
    }
    Object.keys(data?.fields).map(item => {
        setError(item, {
            type: 'custom',
            message: Array.isArray(data?.fields[item]) ? data?.fields[item]?.[0] : data?.fields[item]
        })
    })
}

const generateFormFeedback = (errors, name, message) => {
    const error = errors?.[name]
    return error ? (
        <FormFeedback
            type="invalid">{message || 'Xana vacibdir'}</FormFeedback>
    ) : null
}

const getErrorClassName = (errors, name) => {
    return errors?.[name] ? 'is-invalid' : ''
}

const convertToSelectValue = data => {
    return data?.map(item => {
        return {
            label: item.name,
            value: item.id
        }
    })
}

const generateFileUrl = (url) => {
    let baseURL

    if (window.location.href.includes('localhost')) {
        baseURL = process.env.REACT_APP_FILE_URL
    } else {
        baseURL = process.env.REACT_APP_FILE_URL_PROD
    }
    return baseURL + url
}

const generateGeneratedFileUrl = (url) => {
    let baseURL

    if (window.location.href.includes('localhost')) {
        baseURL = process.env.REACT_APP_BASE_API_URL
    } else {
        baseURL = process.env.REACT_APP_BASE_API_URL_PROD
    }
    return baseURL + url
}

const formatFileSize = (bytes) => {
    if (bytes === 0) {
        return '0 Bytes';
    }

    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const base = 1024;
    const decimalPlaces = 2;

    const unitIndex = Math.floor(Math.log(bytes) / Math.log(base));
    const size = (bytes / Math.pow(base, unitIndex)).toFixed(decimalPlaces);

    return `${size} ${units[unitIndex]}`;
}

const formatUsers = data => {
    return data?.map(item => {
        return {
            ...item,
            label: `${item?.name} ${item?.surname || ''}`,
            value: item.id
        }
    })
}

const createSlug = (str) => {
    str = str?.toLowerCase();
    str = str.replace(/ı/g, 'i')
        .replace(/ü/g, 'u')
        .replace(/ğ/g, 'g')
        .replace(/ö/g, 'o')
        .replace(/ç/g, 'c')
        .replace(/ş/g, 's')
        .replace(/ə/g, 'e')
        .replace(/İ/g, 'i')
    str = str.replace(/[^a-z0-9]/g, '-')
    str = str.replace(/-+/g, '-')
    str = str.replace(/^-|-$/g, '')
    return str
}

const convertFormData = data => {
    const localData = Object.keys(data)
    const params = {...data}
    localData.map(item => {
        if (data[item]?.value && data[item]?.label) {
            params[item] = data[item].value
        }
    })
    return params
}

const convertFormDate = date => {
    if (!date) {
        return null
    }

    if (Array.isArray(date)) {
        return date[0]
    }

    return date

}

const selectElement = (value, label) => {
    return {value, label}
}

const generateNextCallDates = () => {
    return [
        selectElement(new Date(moment(new Date()).add(2, 'days')), '2 gün'),
        selectElement(new Date(moment(new Date()).add(7, 'days')), '7 gün'),
        selectElement(new Date(moment(new Date()).add(1, 'month')), '1 ay'),
        selectElement(new Date(moment(new Date()).add(1, 'year')), '1 il'),
    ]
}

export default {
    generateFormFeedback,
    setApiErrors,
    getErrorClassName,
    convertToSelectValue,
    generateFileUrl,
    formatFileSize,
    formatUsers,
    createSlug,
    convertFormData,
    generateGeneratedFileUrl,
    convertFormDate,
    generateNextCallDates
}
