import axios from "./index";

class Tasks {
    get = (params) => axios.get('tasks', {params})
    getById = id => axios.get(`tasks/${id}`)
    create = data => axios.post('tasks', data)
    update = (data, id) => axios.put(`tasks/${id}`, data)
    delete = id => axios.delete(`tasks/${id}`)
    deleteFile = (id, data) => axios.delete(`tasks/delete-file/${id}`, {data})
}

export default new Tasks()
