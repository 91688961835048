import {Card, CardBody, UncontrolledTooltip} from "reactstrap";
import moment from "moment";
import priorities from "../../data/priorities.json";

const Task = ({setTaskModal, task, item, setTaskConfirmModal}) => {
    const taskIcons = {
        1: <i className="font-size-20 bx bx-chevron-down text-success"/>,
        2: <i className="font-size-18 bx bx-equalizer text-warning"/>,
        3: <i className="font-size-20 bx bx-chevron-up text-danger"/>
    }
    return (
        <Card className="task-card" onClick={() => setTaskModal({
            status: true,
            data: {
                ...task,
                groupId: item.id
            }
        })} key={item.id}>
            <CardBody>
                <div className="d-flex flex-column gap-1">
                    <div className="d-flex justify-content-between">
                        <b>{task.title}</b>
                        <i onClick={e => {
                            e.stopPropagation()
                            setTaskConfirmModal(task.id)
                        }}
                           className="bx bx-trash text-danger font-size-18"/>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span>{task.lastDate ? moment(task.lastDate).format('DD.MM.YYYY') : null}</span>
                        <span className="d-flex"
                              id={`priority-${task.id}`}>{taskIcons[task?.priority]}</span>
                        <UncontrolledTooltip target={`priority-${task.id}`}
                                             placement="bottom">
                            {priorities.find(item => item.value === task.priority).label}
                        </UncontrolledTooltip>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}

export default Task
