import axios from "./index";

class Permission {
    get = () => axios.get('permissions')
    getById = id => axios.get(`permissions/${id}`)
    create = data => axios.post('permissions', data)
    update = (data, id) => axios.put(`permissions/${id}`, data)
    delete = id => axios.delete(`permissions/${id}`)
}

export default new Permission()
