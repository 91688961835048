import {Controller, useForm} from "react-hook-form";
import {Button, Input, Label, ModalBody, ModalFooter, Spinner} from "reactstrap";
import FormHelper from "helpers/form";
import {useEffect, useState} from "react";
import Api from "../../api/task-groups";

const GroupModal = ({fetchData, setForm, form}) => {
    const {handleSubmit, control, setValue, setError, formState: {errors}} = useForm({})
    const [loader, setLoader] = useState(false)

    const submit = async (values) => {
        setLoader(true)
        try {
            if (values?.id) {
                await Api.update(values, values?.id)
            } else {
                await Api.create(values)
            }
            fetchData()
            setForm(false)
        } catch (e) {
            FormHelper.setApiErrors(e.response.data, setError)
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        const data = form?.data
        if (data) {
            setValue('id', data?.id)
            setValue('name', data?.name)
        }
    }, [form])

    return (
        <form onSubmit={handleSubmit(submit)}>
            <ModalBody>
                <div className="mb-3">
                    <Label for="name">Ad</Label>
                    <Controller rules={{
                        required: true
                    }} render={({field: {value, onChange}}) => (
                        <Input
                            name="name"
                            id="name"
                            value={value}
                            onChange={onChange}
                            className={FormHelper.getErrorClassName(errors, 'name')}
                        />
                    )} name="name" control={control}/>
                    {FormHelper.generateFormFeedback(errors, 'name', errors?.name?.message)}
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-end gap-1">
                    <Button outline type="button" onClick={() => setForm({})} color="secondary">Bağla</Button>
                    <Button disabled={loader} type="submit" color="primary">
                        {loader ? <Spinner color="light" size="sm"/> : 'Yadda saxla'}
                    </Button>
                </div>
            </ModalFooter>
        </form>
    )
}

export default GroupModal
