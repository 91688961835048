import {Controller, useForm} from "react-hook-form";
import {Button, Label, ModalBody, ModalFooter, Spinner} from "reactstrap";
import {useState} from "react";
import data from './fields.json'
import {toast} from "react-toastify";
import FieldSettings from "../../api/field-settings";
import modules from '../../data/modules.json'

const SettingsModal = ({selectedFields, setModal, fetchData}) => {
    const [loader, setLoader] = useState(false)
    const {handleSubmit, control} = useForm({defaultValues: selectedFields})

    const submit = async (values) => {
        setLoader(true)
        try {
            await FieldSettings.update({fields: values, moduleName: modules.appeals.value})
            await fetchData()
            setModal(false)
        } catch (e) {
            toast.error('Error')
        } finally {
            setLoader(false)
        }
    }
    return (
        <form onSubmit={handleSubmit(submit)}>
            <ModalBody>
                {data.map(item => (
                    <div className="mb-2" key={item.value}>
                        <Controller render={({field: {value, onChange}}) => (

                            <Label className="d-flex gap-1">
                                <div className="form-check">
                                    <input type="checkbox"
                                           value={value}
                                           checked={value}
                                           onChange={onChange}
                                           name={item.value}
                                           className="form-check-input"/>
                                </div>
                                {item.label}
                            </Label>
                        )} name={item.value} control={control}/>
                    </div>
                ))}
            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-end gap-1">
                    <Button outline type="button" onClick={() => setModal(false)} color="secondary">Bağla</Button>
                    <Button disabled={loader} type="submit" color="primary">
                        {loader ? <Spinner color="light" size="sm"/> : 'Yadda saxla'}
                    </Button>
                </div>
            </ModalFooter>
        </form>
    )
}

export default SettingsModal
