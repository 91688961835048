import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {Card, CardBody, Col, Container, Row,} from "reactstrap";
import {Link} from "react-router-dom";

import classNames from "classnames";

//import Charts
import StackedColumnChart from "./StackedColumnChart";

//import action
import {getChartsData as onGetChartsData} from "../../store/actions";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";
import SocialSource from "./SocialSource";
import ActivityComp from "./ActivityComp";
import TopCities from "./TopCities";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import {withTranslation} from "react-i18next";

//redux
import {useDispatch, useSelector} from "react-redux";
import Statistics from "../../api/statistics";
import User from "../../helpers/user";

const Dashboard = () => {

    const {chartsData} = useSelector(state => ({
        chartsData: state.Dashboard.chartsData
    }));


    const [periodData, setPeriodData] = useState([]);
    const [periodType, setPeriodType] = useState("yearly");
    const [data, setData] = useState([])

    useEffect(() => {
        setPeriodData(chartsData);
    }, [chartsData]);

    const onChangeChartPeriod = pType => {
        setPeriodType(pType);
        dispatch(onGetChartsData(pType));
    };

    const dispatch = useDispatch();

    const fetchStatistics = async () => {
        const {data} = await Statistics.getDashboard()
        setData(data)
    }

    useEffect(() => {
        dispatch(onGetChartsData("yearly"));
    }, [dispatch]);

    useEffect(() => {
        fetchStatistics()
    }, [])

    document.title = "Dashboard | Azstudy";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={"Ana səhifə"}
                    />
                    <Row>
                        <Col xl="4">
                            <WelcomeComp/>
                            <MonthlyEarning/>
                        </Col>
                        <Col xl="8">
                            <Row>
                                {(User.isAdmin() || User.isAssistant() || User.isReception() || User.isEducationExpert()) && (
                                    <Col md="4">
                                        <Card className="mini-stats-wid">
                                            <CardBody>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium">
                                                            Əlavə edilmiş müraciətlər
                                                        </p>
                                                        <h4 className="mb-0">{data?.appealsCount}</h4>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                )}
                                {(User.isAdmin() || User.isSuperAdmin() || User.isDocumentExpert()) && (
                                    <Col md="4">
                                        <Card className="mini-stats-wid">
                                            <CardBody>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium">
                                                            Əlavə edilmiş müqavilələr
                                                        </p>
                                                        <h4 className="mb-0">{data?.contractsCount}</h4>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                )}
                            </Row>

                            <Card>
                                <CardBody>
                                    <div className="d-sm-flex flex-wrap">
                                        <h4 className="card-title mb-4">Email Sent</h4>
                                        <div className="ms-auto">
                                            <ul className="nav nav-pills">
                                                <li className="nav-item">
                                                    <Link
                                                        to="#"
                                                        className={classNames(
                                                            {active: periodType === "weekly"},
                                                            "nav-link"
                                                        )}
                                                        onClick={() => {
                                                            onChangeChartPeriod("weekly");
                                                        }}
                                                        id="one_month"
                                                    >
                                                        Week
                                                    </Link>{" "}
                                                </li>
                                                <li className="nav-item">
                                                    <Link
                                                        to="#"
                                                        className={classNames(
                                                            {active: periodType === "monthly"},
                                                            "nav-link"
                                                        )}
                                                        onClick={() => {
                                                            onChangeChartPeriod("monthly");
                                                        }}
                                                        id="one_month"
                                                    >
                                                        Month
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link
                                                        to="#"
                                                        className={classNames(
                                                            {active: periodType === "yearly"},
                                                            "nav-link"
                                                        )}
                                                        onClick={() => {
                                                            onChangeChartPeriod("yearly");
                                                        }}
                                                        id="one_month"
                                                    >
                                                        Year
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <div className="clearfix"></div> */}
                                    <StackedColumnChart periodData={periodData}
                                                        dataColors='["--bs-primary", "--bs-warning", "--bs-success"]'/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl="4">
                            <SocialSource/>
                        </Col>
                        <Col xl="4">
                            <ActivityComp/>
                        </Col>

                        <Col xl="4">
                            <TopCities/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

Dashboard.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
