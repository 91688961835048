import axios from './index'

class Contract {
    get = (params) => axios.get('contracts', {params})
    export = (params) => axios.get('contracts/export', {params})
    getById = id => axios.get(`contracts/${id}`)
    getStudentInfo = id => axios.get(`contracts/student-info/${id}`)
    create = data => axios.post('contracts', data)
    update = (data, id) => axios.put(`contracts/${id}`, data)
    delete = id => axios.delete(`contracts/${id}`)
    deleteFile = (id, data) => axios.delete(`contracts/delete-file/${id}`, {data})
}

export default new Contract()
