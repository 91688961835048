import {Controller, useForm} from "react-hook-form";
import {
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Button,
    Input,
    Label,
    ModalBody,
    ModalFooter,
    Spinner,
    UncontrolledAccordion
} from "reactstrap";
import FormHelper from "helpers/form";
import {useEffect, useState} from "react";
import PermissionsApi from "../../../api/permissions";
import modules from '../../../data/modules.json'

const AddPermission = ({fetchData, setPermissionForm, permissionForm}) => {
    const actionsElement = (value, label) => {
        return {value, label}
    }

    const actions = [
        actionsElement('can_view', 'Modulu görsün'),
        actionsElement('can_add', 'Əlavə etsin'),
        actionsElement('can_update', 'Yeniləsin'),
        actionsElement('can_delete', 'Silsin'),
    ]

    const {handleSubmit, control, setValue, setError, formState: {errors}} = useForm({})
    const [loader, setLoader] = useState(false)

    const submit = async (values) => {
        setLoader(true)
        try {
            if (values?.id) {
                await PermissionsApi.update(values, values?.id)
            } else {
                await PermissionsApi.create(values)
            }
            fetchData()
            setPermissionForm(false)
        } catch (e) {
            FormHelper.setApiErrors(e.response.data, setError)
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        const data = permissionForm?.data
        if (data) {
            setValue('id', data?.id)
            setValue('name', data?.name)
            setValue('access', data?.access)
        }
    }, [permissionForm])

    return (
        <form onSubmit={handleSubmit(submit)}>
            <ModalBody>
                <div className="mb-3">
                    <Label for="name">Səlahiyyət adı</Label>
                    <Controller rules={{
                        required: true
                    }} render={({field: {value, onChange}}) => (
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            value={value}
                            onChange={onChange}
                            invalid={errors?.name}
                        />
                    )} name="name" control={control}/>
                    {FormHelper.generateFormFeedback(errors, 'name', errors?.name?.message)}
                </div>
                <div className="mb-3">
                    <UncontrolledAccordion>
                        {Object.values(modules).map(item => (
                            <AccordionItem key={item.value}>
                                <AccordionHeader targetId={item.value}>
                                    <b>{item.label}</b>
                                </AccordionHeader>
                                <AccordionBody accordionId={item.value}>
                                    {actions.map(action => (
                                        <Controller render={({field: {value, onChange}}) => (
                                            <Label className="d-flex gap-1">
                                                <div className="form-check">
                                                    <input type="checkbox"
                                                           value={value}
                                                           checked={value}
                                                           onChange={onChange}
                                                           name={item.value}
                                                           className="form-check-input"/>
                                                </div>
                                                {action.label}
                                            </Label>
                                        )} name={`access.${item.value}.${action.value}`} control={control}/>
                                    ))}
                                    {item.value === 'contracts' && (
                                      <Controller render={({field: {value, onChange}}) => (
                                        <Label className="d-flex gap-1">
                                            <div className="form-check">
                                                <input type="checkbox"
                                                       value={value}
                                                       checked={value}
                                                       onChange={onChange}
                                                       name={item.value}
                                                       className="form-check-input"/>
                                            </div>
                                            Arxiv sənədləri görsün
                                        </Label>
                                      )} name={`access.contracts.can_view_archive`} control={control}/>
                                    )}
                                </AccordionBody>
                            </AccordionItem>
                        ))}
                    </UncontrolledAccordion>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-end gap-1">
                    <Button outline type="button" onClick={() => setPermissionForm({})} color="secondary">Bağla</Button>
                    <Button disabled={loader} type="submit" color="primary">
                        {loader ? <Spinner color="light" size="sm"/> : 'Yadda saxla'}
                    </Button>
                </div>
            </ModalFooter>
        </form>
    )
}

export default AddPermission
