import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Container,
    Label,
    Row,
    Spinner,
    UncontrolledTooltip
} from "reactstrap";
import {useEffect, useState} from "react";
import Contract from "../../api/contract";
import {useParams} from "react-router-dom";
import Form from "../../helpers/form";
import PDFMerger from "pdf-merger-js/browser";
import {toast} from "react-toastify";
import degress from '../../data/degrees.json'
import educationTypes from '../../data/educationTypes.json'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Helpler from "../../helpers/Helpler";

const StudentInfo = () => {
    document.title = 'Tələbə | Azstudy'
    const {id} = useParams()
    const [isFetching, setIsFetcing] = useState(true)
    const [data, setData] = useState(null)
    const [downloadLoader, setDownloadLoader] = useState(false)

    const fetchData = async () => {
        setIsFetcing(true)
        const {data} = await Contract.getStudentInfo(id)
        setData(data)
        setIsFetcing(false)
    }

    const generateMergedFiles = async () => {
        setDownloadLoader(true)
        const merger = new PDFMerger();
        for (const file of data?.files) {
            if (file?.file?.endsWith('.pdf')) {
                await merger.add(Form.generateFileUrl(file.file))
            } else if (file?.file?.match(/\.(jpg|jpeg|png|gif|bmp)$/i)) {
                await merger.add(await Helpler.createPDFFromImage(Form.generateFileUrl(file.file)))
            }
        }
        await merger.save(data?.fullName);
        setDownloadLoader(false)
    }

    const downloadPdf = async item => {
        const merger = new PDFMerger();
        await merger.add(Form.generateFileUrl(item.file))
        await merger.save(item.description);
    }


    useEffect(() => {
        fetchData()
    }, [id])

    return (
        <Container className="mt-5">
            <Row className="justify-content-center">
                <Col sm={12} md={6}>
                    <Card>
                        {isFetching ? (
                            <div className="py-5 d-flex justify-content-center">
                                <Spinner size="lg" color="primary"/>
                            </div>
                        ) : (
                            data ? <>
                                <CardHeader className="shadow">
                                    <CardTitle>
                                        <div className="d-flex align-items-center justify-content-between font-size-16">
                                            <span>{data?.fullName}</span>
                                            <CopyToClipboard text={window.location.href}
                                                             onCopy={() => toast.success('Kopyalandı')}>
                                                <Button id="copy" color="warning"><i
                                                    className="bx bx-copy"/></Button>
                                            </CopyToClipboard>
                                            <UncontrolledTooltip target="copy" placement="bottom">
                                                Kopyala
                                            </UncontrolledTooltip>
                                        </div>
                                    </CardTitle>
                                </CardHeader>
                                <CardBody className="font-size-16">
                                    <div className="mb-3">
                                        <span>Universitetlər :</span>{' '}
                                        <b>{data?.university || 'Məlumat yoxdur'}</b>
                                    </div>
                                    <div className="mb-3">
                                        <span>İxtisaslar :</span>{' '}
                                        <b>{data?.specialty || 'Məlumat yoxdur'}</b>
                                    </div>
                                    <div className="mb-3">
                                        <span>Dərəcə :</span>{' '}
                                        <b className="d-inline-block me-2">{degress?.[data?.degree]?.label || 'Məlumat yoxdur'}</b>
                                        {data?.educationType && <Badge
                                            color="primary">{Object.values(educationTypes).find(item => item?.value === data?.educationType)?.label}</Badge>}
                                    </div>
                                    <div className="mb-3">
                                        <span>Qeyd :</span>{' '}
                                        <b>{data?.note || 'Məlumat yoxdur'}</b>
                                    </div>
                                    {data?.files?.length > 0 && (
                                        <div className="table-responsive">
                                            <hr/>
                                            <Label>Fayllar</Label>
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th>Açıqlama</th>
                                                    <th>Ölçü</th>
                                                    <th>
                                                        <Button color="primary"
                                                                id="download"
                                                                disabled={downloadLoader || data?.files?.length < 2}
                                                                onClick={generateMergedFiles}
                                                                size="sm">
                                                            {downloadLoader ? <Spinner size="sm" color="light"/> :
                                                                <i
                                                                    className="bx bx-download"/>}
                                                        </Button>
                                                        <UncontrolledTooltip target="download" placement="bottom">
                                                            Hamsın birləşdir
                                                        </UncontrolledTooltip>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {data?.files?.map((item) => (
                                                    <tr key={item.id}>
                                                        <td>{item.description}</td>
                                                        <td>{Form.formatFileSize(item.size)}</td>
                                                        <td>
                                                            <div className="d-flex gap-1">
                                                                {item?.file?.includes('.pdf') && (
                                                                    <>
                                                                        <Button color="success"
                                                                                type="button"
                                                                                id={`download-${item.id}`}
                                                                                onClick={() => downloadPdf(item)}
                                                                                size="sm"><i
                                                                            className="bx bx-download"/></Button>
                                                                        <UncontrolledTooltip
                                                                            target={`download-${item.id}`}
                                                                            placement="bottom">
                                                                            {item.description} Yüklə
                                                                        </UncontrolledTooltip>
                                                                    </>
                                                                )}
                                                                <a target="_blank"
                                                                   id={`show-${item.id}`}
                                                                   href={Form.generateFileUrl(item?.file)}
                                                                   className="btn btn-sm btn-primary">
                                                                    {downloadLoader ?
                                                                        <Spinner size="sm" color="light"/> :
                                                                        <i
                                                                            className="bx bx-show"/>}
                                                                </a>
                                                                <UncontrolledTooltip target={`show-${item.id}`}
                                                                                     placement="bottom">
                                                                    {item.description} Bax
                                                                </UncontrolledTooltip>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <td/>
                                                    <td>
                                                        <Badge color="success">
                                                            {Form.formatFileSize(data?.files?.map(item => item?.size).reduce((accumullator, currentValue) => accumullator + currentValue))}
                                                        </Badge>
                                                    </td>
                                                    <td/>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </CardBody>
                            </> : <div className="d-flex justify-content-center py-5">
                                <h1>Məlumat tapılmadı</h1>
                            </div>
                        )}
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default StudentInfo
