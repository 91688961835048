import React, {useCallback, useEffect, useRef} from "react";
import {Link, useLocation} from "react-router-dom";
import PropTypes from "prop-types";


// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "components/Common/withRouter";

//i18n
import {withTranslation} from "react-i18next";
import modules from '../../data/modules.json'
import Can from "../Permissions/Can";

const SidebarContent = props => {
    const ref = useRef();
    const activateParentDropdown = useCallback((item) => {
        item.classList.add("active");
        const parent = item.parentElement;
        const parent2El = parent.childNodes[1];

        if (parent2El && parent2El.id !== "side-menu") {
            parent2El.classList.add("mm-show");
        }

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show"); // ul tag

                const parent3 = parent2.parentElement; // li tag

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement; // ul
                    if (parent4) {
                        parent4.classList.add("mm-show"); // ul
                        const parent5 = parent4.parentElement;
                        if (parent5) {
                            parent5.classList.add("mm-show"); // li
                            parent5.childNodes[0].classList.add("mm-active"); // a tag
                        }
                    }
                }
            }
            scrollElement(item);
            return false;
        }
        scrollElement(item);
        return false;
    }, []);

    const removeActivation = (items) => {
        for (var i = 0; i < items.length; ++i) {
            var item = items[i];
            const parent = items[i].parentElement;

            if (item && item.classList.contains("active")) {
                item.classList.remove("active");
            }
            if (parent) {
                const parent2El =
                    parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
                        ? parent.childNodes[1]
                        : null;
                if (parent2El && parent2El.id !== "side-menu") {
                    parent2El.classList.remove("mm-show");
                }

                parent.classList.remove("mm-active");
                const parent2 = parent.parentElement;

                if (parent2) {
                    parent2.classList.remove("mm-show");

                    const parent3 = parent2.parentElement;
                    if (parent3) {
                        parent3.classList.remove("mm-active"); // li
                        parent3.childNodes[0].classList.remove("mm-active");

                        const parent4 = parent3.parentElement; // ul
                        if (parent4) {
                            parent4.classList.remove("mm-show"); // ul
                            const parent5 = parent4.parentElement;
                            if (parent5) {
                                parent5.classList.remove("mm-show"); // li
                                parent5.childNodes[0].classList.remove("mm-active"); // a tag
                            }
                        }
                    }
                }
            }
        }
    };

    const path = useLocation();
    const activeMenu = useCallback(() => {
        const pathName = path.pathname;
        let matchingMenuItem = null;
        const ul = document.getElementById("side-menu");
        const items = ul.getElementsByTagName("a");
        removeActivation(items);

        for (let i = 0; i < items.length; ++i) {
            if (pathName === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }
    }, [path.pathname, activateParentDropdown]);

    useEffect(() => {
        ref.current.recalculate();
    }, []);

    useEffect(() => {
        new MetisMenu("#side-menu");
        activeMenu();
    }, []);

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        activeMenu();
    }, [activeMenu]);

    function scrollElement(item) {
        if (item) {
            const currentPosition = item.offsetTop;
            if (currentPosition > window.innerHeight) {
                ref.current.getScrollElement().scrollTop = currentPosition - 300;
            }
        }
    }

    return (
        <React.Fragment>
            <SimpleBar className="h-100" ref={ref}>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li>
                            <Link to="/dashboard">
                                <i className="bx bx-home"/>
                                <span>Ana səhifə</span>
                            </Link>
                        </li>
                        <Can moduleName={modules.users.value} keys={['can_view']}>
                            <li>
                                <Link to="/users">
                                    <i className="bx bx-user"/>
                                    <span>İstifadəçilər</span>
                                </Link>
                            </li>
                        </Can>
                        <Can moduleName={modules.tasks.value} keys={['can_view']}>
                            <li>
                                <Link to="/tasks">
                                    <i className="bx bx-task"/>
                                    <span>Tapşırıqlar</span>
                                </Link>
                            </li>
                        </Can>
                        <Can moduleName={modules.appeals.value} keys={['can_view']}>
                            <li>
                                <Link to="/appeals">
                                    <i className="bx bx-book"/>
                                    <span>Müraciətlər</span>
                                </Link>
                            </li>
                        </Can>
                        <Can moduleName={modules.contracts.value} keys={['can_view']}>
                            <li>
                                <Link to="/contracts">
                                    <i className="bx bx-id-card"/>
                                    <span>Müqavilələr</span>
                                </Link>
                            </li>
                        </Can>
                        <li>
                            <Link to="/#" className="has-arrow">
                                <i className="bx bx-bar-chart"></i>
                                <span>Statistikalar</span>
                            </Link>
                            <ul className="sub-menu">
                                <Can moduleName={modules.appealStatistics.value} keys={['can_view']}>
                                    <li>
                                        <Link to="/statistics/appeals">Müraciətlər</Link>
                                    </li>
                                </Can>
                                <Can moduleName={modules.contractStatistics.value} keys={['can_view']}>
                                    <li>
                                        <Link to="/statistics/contracts">Müqavilələr</Link>
                                    </li>
                                </Can>
                            </ul>
                        </li>
                        <li>
                            <Link to="/#" className="has-arrow">
                                <i className="bx bx-cog"></i>
                                <span>Tənzimləmələr</span>
                            </Link>
                            <ul className="sub-menu">
                                <Can moduleName={modules.permissions.value} keys={['can_view']}>
                                    <li>
                                        <Link to="/settings/permissions">Səlahiyyətlər</Link>
                                    </li>
                                </Can>
                                <Can moduleName={modules.curations.value} keys={['can_view']}>
                                    <li>
                                        <Link to="/settings/curations">Kurasiyalar</Link>
                                    </li>
                                </Can>
                            </ul>
                        </li>
                    </ul>
                </div>
            </SimpleBar>
        </React.Fragment>
    );
};

SidebarContent.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
