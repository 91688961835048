import React from "react";
import Dashboard from "../pages/Dashboard/index";
import modules from '../data/modules.json'
import {Navigate} from "react-router-dom";
import Users from "../pages/Users";
import Contracts from "../pages/Contracts";
import Appeals from "../pages/Appeals";
import Login from "../pages/Authentication/Login";
import Register from "../pages/Authentication/Register";
import UserProfile from "../pages/Authentication/user-profile";
import Permissions from "../pages/Settings/Permissions";
import StudentInfo from "../pages/StudentInfo";
import Curations from "../pages/Settings/Curations";
import Tasks from "../pages/Tasks";

import AppealStatistics from '../pages/Statistics/Appeals'
import ContractStatistics from '../pages/Statistics/Contracts'

const authProtectedRoutes = [
    {path: "/dashboard", component: <Dashboard/>,},
    {path: '/profile', component: <UserProfile/>,},
    {path: '/users', component: <Users/>, name: modules.users.value},

    {path: '/tasks', component: <Tasks/>, name: modules.tasks.value},

    {path: '/contracts', component: <Contracts/>, name: modules.contracts.value},
    {path: '/contracts/add', component: <Contracts/>, name: modules.contracts.value},
    {path: '/contracts/edit/:id', component: <Contracts/>, name: modules.contracts.value},

    {path: '/appeals', component: <Appeals/>, name: modules.appeals.value},
    {path: '/appeals/add', component: <Appeals/>, name: modules.appeals.value},
    {path: '/appeals/edit/:id', component: <Appeals/>, name: modules.appeals.value},

    {path: '/statistics/appeals', component: <AppealStatistics/>, name: modules.appealStatistics.value},
    {path: '/statistics/contracts', component: <ContractStatistics/>, name: modules.contractStatistics.value},

    {path: '/settings/permissions', component: <Permissions/>, name: modules.permissions.value},
    {path: '/settings/curations', component: <Curations/>, name: modules.curations.value}

];

const publicRoutes = [
    {path: '/login', component: <Login/>},
    {path: '/register', component: <Register/>},
    {path: '/student/:id/:fullName', component: <StudentInfo/>},
    {path: '/', component: <Navigate to="/dashboard"/>}
]

export {authProtectedRoutes, publicRoutes};
