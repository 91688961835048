import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {Col, Row} from "reactstrap";
import Status from "./Status";

const Appeals = () => {
    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs breadcrumbItem={`MÜRACİƏTLƏR STATİSTİKASI`}/>
                <Row>
                    <Col sm={6}>
                        <Status/>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Appeals
