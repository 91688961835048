import Form from "../../helpers/form";
import Tasks from "../../api/tasks";
import {useEffect, useState} from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const PrevFilesModal = ({taskId, files, fetchData}) => {
    const [localFiles, setLocalFiles] = useState(files)
    const [fileIndex, setFileIndex] = useState(0)
    const [isGallery, setIsGallery] = useState(false)

    const getFileMimeType = file => {
        const splitedFile = file.split('.')
        return splitedFile[splitedFile.length - 1]
    }


    const mimeTypes = {
        png: file => <img src={Form.generateFileUrl(file)} alt="File"/>,
        jpg: file => <img src={Form.generateFileUrl(file)} alt="File"/>,
        jpeg: file => <img src={Form.generateFileUrl(file)} alt="File"/>,
        jfif: file => <img src={Form.generateFileUrl(file)} alt="File"/>,
        pdf: file => <a href={Form.generateFileUrl(file)} target="_blank">Pdf fayl</a>,
        mp4: file => <video controls src={Form.generateFileUrl(file)}/>
    }

    const deleteFile = async (e, id) => {
        e.stopPropagation()
        await Tasks.deleteFile(taskId, {fileId: id})
        fetchData()
        setLocalFiles(prev => (prev.filter(item => item.id !== id)))
    }

    const onMoveNextRequest = () => {
        const index = files.findIndex(item => item.id === fileIndex)
        if (files[index + 1]) {
            setFileIndex(files[index + 1].id)
            return
        }
        setFileIndex(files[0].id)
    }

    const onMovePrevRequest = () => {
        const index = files.findIndex(item => item.id === fileIndex)
        if (files[index - 1]) {
            setFileIndex(files[index - 1].id)
            return
        }
        setFileIndex(files[files.length - 1].id)
    }

    const mainFile = files.find(item => item.id === fileIndex)?.file
    const mainFileIndex = files.findIndex(item => item.id === fileIndex)
    const nextFile = files.find(item => item.id === fileIndex[mainFileIndex + 1]?.id)?.file
    const prevFile = files.find(item => item.id === fileIndex[mainFileIndex - 1]?.id)?.file

    useEffect(() => {
        setLocalFiles(files)
    }, [files])

    return localFiles?.length > 0 ? (
        <div className="d-flex w-100 task-files">
            {isGallery ? (
                <Lightbox
                    mainSrc={Form.generateFileUrl(mainFile)}
                    nextSrc={Form.generateFileUrl(nextFile)}
                    prevSrc={Form.generateFileUrl(prevFile)}
                    enableZoom={true}
                    onCloseRequest={() => setIsGallery(false)}
                    onMovePrevRequest={onMovePrevRequest}
                    onMoveNextRequest={onMoveNextRequest}
                />
            ) : null}
            {localFiles?.map((item) => (
                <div className="task-files-container" onClick={() => {
                    setFileIndex(item.id)
                    setIsGallery(true)
                }}>
                    {mimeTypes[getFileMimeType(item?.file)](item.file)}
                    <i onClick={(e) => deleteFile(e, item.id)} class="bx bx-trash text-danger"/>
                </div>
            ))}
        </div>
    ) : null
}

export default PrevFilesModal
