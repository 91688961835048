import PropTypes from "prop-types";
import React from "react";
import {connect} from "react-redux";
import withRouter from "components/Common/withRouter";

//i18n
import {withTranslation} from "react-i18next";
import SidebarContent from "./SidebarContent";

import {Link} from "react-router-dom";
import Logo from '../../assets/images/logo.png'

const Sidebar = props => {

    return (
        <React.Fragment>
            <div className="vertical-menu pt-4">
                <Link to="/" className="logo logo-light text-center">
                    <img width="50px" height="50px" src={Logo} alt="AzStudy"/>
                </Link>
                <div data-simplebar className="h-100">
                    {props.type !== "condensed" ? <SidebarContent/> : <SidebarContent/>}
                </div>
                <div className="sidebar-background"></div>
            </div>
        </React.Fragment>
    );
};

Sidebar.propTypes = {
    type: PropTypes.string,
};

const mapStatetoProps = state => {
    return {
        layout: state.Layout,
    };
};
export default connect(
    mapStatetoProps,
    {}
)(withRouter(withTranslation()(Sidebar)));
