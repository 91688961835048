import Breadcrumbs from "../../components/Common/Breadcrumb";
import {useEffect, useState} from "react";
import {Badge, Button, Card, CardBody, Col, Input, Modal, ModalHeader, Row, UncontrolledTooltip} from "reactstrap";
import Contract from "../../api/contract";
import documentStatuses from '../../data/documentStatuses.json'
import contractStatuses from '../../data/contractStatuses.json'
import educationTypes from '../../data/educationTypes.json'
import degrees from '../../data/degrees.json'
import modules from '../../data/modules.json'
import visa from '../../data/visa.json'
import moment from "moment";
import ConfirmModal from "../../components/Common/ConfirmModal";
import Select from "react-select";
import SettingsModal from "./SettingsModal";
import FieldSettings from "../../api/field-settings";
import Can from "../../components/Permissions/Can";
import CustomPagination from "../../components/CustomPagination";
import {useForm} from "react-hook-form";
import Filters from "./Filters";
import Form from "../../helpers/form";
import AddContract from "./AddContract";
import LocalStorageManager from "../../helpers/localStorageManager"

const Contracts = () => {
    const permissions = LocalStorageManager.get('user')?.permissionId?.access
    const [data, setData] = useState([])
    const [isFetching, setIsFetching] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)
    const [settingsModal, setSettingsModal] = useState(false)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [total, setTotal] = useState(0)
    const [searchValue, setSearchValue] = useState('')
    const [selectedTab,setSelectedTab] = useState(new Date().getFullYear())
    const [selectedFields, setSelectedFields] = useState({
        id: true,
        fullName: true,
        code: true,
        conctractDate: true,
        price: true,
        user: true,
        uploadedFiles: true,
        lastLog: true
    })

    const [contractForm, setContractForm] = useState({})

    document.title = 'Müqavilələr | Azstudy'

    const filterForm = useForm({
        educationType: null,
        code: null,
        user_id: null,
        documentStatus: null,
        status: null,
        degree: null
    })

    const tabElement = (value,label) => {
        return {value,label}
    }

    const tabs = [
      tabElement(2023,'2023'),
      tabElement(2024,'2024'),
    ]

    const changeFieldValue = async (e, id, key) => {
        if (e.keyCode === 13) {
            await Contract.update({
                [key]: e.target.value
            }, id)
        }
    }

    const changeSelectValue = async (data, id, key, reset) => {
        await Contract.update({
            [key]: data?.value
        }, id)
        reset && fetchData(false,searchValue ? {search:searchValue} : null)
    }

    const fetchData = async (showLoader = true, additionalSearch = null) => {
        setIsFetching(showLoader)
        const params = filterForm.getValues()
        const {data} = await Contract.get({
            page,
            can_view_archive : permissions?.contracts?.can_view_archive,
            limit,
            ...(additionalSearch || {
                educationType: params?.educationType?.value,
                code: params?.code?.value,
                user_id: params?.user_id?.value,
                documentStatus: params?.documentStatus?.value,
                status: params?.status?.value,
                degree: params?.degree?.value,
                date: selectedTab
            }),
        })
        setTotal(data?.total)
        setData(data?.data?.map((item, index) => {
            const status = Object.values(contractStatuses).find(data => data.value === item.status)
            const documentStatus = Object.values(documentStatuses).find(data => data.value === item.documentStatus)
            const visaStatus = visa.find(data => data.value === item.visa)
            const educationType = Object.values(educationTypes).find(data => data.value === item.educationType)
            const degree = Object.values(degrees).find(data => data.value === item.degree)
            return {
                ...item,
                code:
                    <input
                        onKeyDown={(e) => changeFieldValue(e, item.id, 'code')}
                        required
                        defaultValue={item.code}
                        className="border-0"/>,
                price: <input
                    onKeyDown={(e) => changeFieldValue(e, item.id, 'price')}
                    required
                    type="number"
                    defaultValue={item.price}
                    className="border-0"/>,
                visa: <div className="d-flex flex-column gap-1">
                    <Select
                      onChange={e => changeSelectValue(e, item.id, 'visa', true)}
                      options={visa}
                      styles={{
                          control: (provided) => ({
                              ...provided,
                              backgroundColor: visaStatus?.color,
                              width: '200px',
                          }),
                      }}
                      defaultValue={visaStatus}/>
                </div>,
                fullName: <input
                    onKeyDown={(e) => changeFieldValue(e, item.id, 'fullName')}
                    required
                    type="text"
                    defaultValue={item.fullName}
                    className="border-0"/>,
                note: <input
                    onKeyDown={(e) => changeFieldValue(e, item.id, 'note')}
                    required
                    type="text"
                    defaultValue={item.note}
                    className="border-0"/>,
                lastLog: <input
                    onKeyDown={(e) => changeFieldValue(e, item.id, 'log')}
                    required
                    type="text"
                    defaultValue={item?.log_ids?.[item?.log_ids?.length - 1]?.log}
                    className="border-0"/>,
                index: <div className="d-flex align-items-center gap-1">
                    <Badge color="primary">{index + 1}</Badge>
                </div>,
                degree: degree?.label,
                contractDate: item?.contractDate ? moment(item?.contractDate).format('DD.MM.YYYY') : null,
                birthDate: item?.birthDate ? moment(item?.birthDate).format('DD.MM.YYYY') : null,
                educationType: <Select
                    className="w-200px"
                    onChange={e => changeSelectValue(e, item.id, 'educationType')}
                    options={Object.values(educationTypes)}
                    defaultValue={educationType}/>,
                documentStatus: <div className="d-flex flex-column gap-1">
                    <Select
                        onChange={e => changeSelectValue(e, item.id, 'documentStatus', true)}
                        options={Object.values(documentStatuses)}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                backgroundColor: documentStatus?.color,
                                width: '200px',
                            }),
                        }}
                        defaultValue={documentStatus}/>
                </div>,
                status: <div className="d-flex flex-column gap-1">
                    <Select
                        onChange={e => changeSelectValue(e, item.id, 'status', true)}
                        options={Object.values(contractStatuses)}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                backgroundColor: status?.color,
                                width: '200px',
                            }),
                        }}
                        defaultValue={status}/>
                </div>,
                user: `${item.user_id?.name} ${item?.user_id?.surname}`,
                missingFiles: item?.missingFiles?.join(', '),
                uploadedFiles: <div
                    style={{minWidth: '200px'}}>{item?.files?.map(item => item?.description)?.join(', ')}</div>,
                createdAt: moment(item?.createdAt).format('DD.MM.YYYY HH:mm'),
                updatedAt: moment(item?.updatedAt).format('DD.MM.YYYY HH:mm'),
                actions: <div className="d-flex gap-1">
                    <Can moduleName={modules.contracts.value} keys={['can_update']}>
                        <Button
                            onClick={() => setContractForm({status: true, data: item.id})}
                            type="button"
                            color="primary"
                            id={`contract-${item.id}-edit`}
                        >
                            <i className="bx bx-pencil"/>
                        </Button>
                        <UncontrolledTooltip target={`contract-${item.id}-edit`} placement="bottom">
                            Düzəliş et
                        </UncontrolledTooltip>
                    </Can>
                    <Button
                        tag="a"
                        href={`/student/${item.id}/${Form.createSlug(item?.fullName)}`}
                        target="_blank"
                        type="button"
                        color="warning"
                        id={`student-${item.id}`}
                    >
                        <i className="bx bx-info-circle"/>
                    </Button>
                    <UncontrolledTooltip target={`student-${item.id}`} placement="bottom">
                        Link
                    </UncontrolledTooltip>
                    <Can moduleName={modules.contracts.value} keys={['can_delete']}>
                        <Button
                            type="button"
                            color="danger"
                            id={`contract-${item.id}-delete`}
                            onClick={() => setConfirmModal(item.id)}
                        >
                            <i className="bx bx-trash"/>
                        </Button>
                        <UncontrolledTooltip target={`contract-${item.id}-delete`} placement="bottom">
                            Sil
                        </UncontrolledTooltip>
                    </Can>
                </div>
            }
        }))
        setIsFetching(false)
    }

    const fetchSettings = async () => {
        const fields = await FieldSettings.get({moduleName: modules.contracts.value})
        fields?.data && setSelectedFields(fields?.data?.fields)
    }

    const deleteContract = async () => {
        await Contract.delete(confirmModal)
        fetchData()
    }

    const search = async e => {
        e.preventDefault()
        if (!searchValue.length) {
            fetchData(true)
            return
        }
        fetchData(true, {search: searchValue,can_view_archive : permissions?.contracts?.can_view_archive,date:selectedTab})

    }

    useEffect(() => {
        fetchSettings()
    }, [])

    useEffect(() => {
        if(searchValue?.length) {
            fetchData(true, {search: searchValue,can_view_archive : permissions?.contracts?.can_view_archive,date:selectedTab})
        }else {
            fetchData()
        }
    }, [page, limit,selectedTab])

    return (
        <div className="page-content">
            <ConfirmModal active={confirmModal} setActive={setConfirmModal} callback={deleteContract}/>
            <Modal className="modal-dialog-centered" isOpen={settingsModal} toggle={() => setSettingsModal(false)}>
                <ModalHeader
                    toggle={() => setSettingsModal(false)}>Tənzimləmələri dəyiş</ModalHeader>
                <SettingsModal fetchData={fetchSettings} selectedFields={selectedFields} setModal={setSettingsModal}/>
            </Modal>
            <Modal size="lg" className="modal-dialog-centered" isOpen={contractForm?.status}
                   toggle={() => setContractForm({})}>
                <ModalHeader
                    toggle={() => setContractForm({})}>{contractForm?.data ? 'Müqaviləni yenilə' : 'Əlavə et'}</ModalHeader>
                <AddContract fetchData={fetchData} setModal={setContractForm} modal={contractForm}/>
            </Modal>
            <div className="container-fluid">
                <Breadcrumbs breadcrumbItem={`MÜQAVİLƏLƏR (${total})`}/>
                <Row>
                    <Col sm={12}>
                        <Filters form={filterForm} fetchData={fetchData}/>
                    </Col>
                    <Col sm={12}>
                        <Card>
                            <CardBody>
                                <div
                                  className="d-flex flex-column-reverse flex-md-row align-items-start justify-content-between gap-2 mb-3">
                                    <div className="d-flex flex-column flex-md-row w-100 gap-1">
                                        <Can moduleName={modules.contracts.value} keys={['can_add']}>
                                            <Button
                                              onClick={() => setContractForm({ status: true })}
                                              type="button"
                                              color="success"
                                              className="btn-label"
                                            >
                                                <i className="bx bx-plus label-icon" /> Əlavə et
                                            </Button>
                                        </Can>
                                        <Button
                                          onClick={() => setSettingsModal(true)}
                                          type="button"
                                          color="secondary"
                                          className="btn-label"
                                        >
                                            <i className="bx bx-cog label-icon" /> Tənzimləmələr
                                        </Button>
                                    </div>
                                    <form onSubmit={search} className="app-search p-0">
                                        <div className="position-relative">
                                            <Input disabled={isFetching} placeholder="Axtar" value={searchValue}
                                                   onChange={e => setSearchValue(e.target.value)} id="search-input" />
                                            <UncontrolledTooltip target="search-input" placement="bottom">
                                                Ad soyad, ata, ana, passport nömrəsi, telefon, kod, universitet, ixtisas
                                            </UncontrolledTooltip>
                                            <span onClick={e => {
                                                if (!isFetching) {
                                                    search(e)
                                                }
                                            }}
                                                  class="bx bx-search-alt cursor-pointer" />
                                        </div>
                                    </form>
                                </div>
                                <nav className="nav">

                                </nav>
                                <ul className="nav nav-tabs">
                                    {tabs.map(item => (
                                      <li className="nav-item" onClick={() => setSelectedTab(item.value)}>
                                        <a key={item.value} className={`nav-link ${item.value === selectedTab && 'active'}`}>{item.label}</a>
                                      </li>
                                    ))}
                                </ul>
                                <div className="table-responsive" style={{ paddingBottom: '170px' }}>
                                    {isFetching ? (
                                      Array.from({ length: 10 }).map((item, index) => (
                                        <p className="placeholder-glow mb-2" key={index}>
                                            <span className="placeholder py-4 col-12" />
                                        </p>
                                      ))
                                    ) : data?.length > 0 && (
                                      <table className="table table-bordered">
                                          <thead>
                                          <tr>
                                              {selectedFields?.id && <th>ID</th>}
                                              {selectedFields?.user && <th>İstifadəçi</th>}
                                              {selectedFields?.fullName && <th>Ad soyad</th>}
                                              {selectedFields?.birthDate && <th>Doğum tarixi</th>}
                                              {selectedFields?.passportNumber && <th>Passport nömrəsi</th>}
                                              {selectedFields?.fatherName && <th>Ata adı</th>}
                                              {selectedFields?.motherName && <th>Ana adı</th>}
                                              {selectedFields?.phone && <th>Telefon</th>}
                                              {selectedFields?.code && <th>Kod</th>}
                                              {selectedFields?.contractDate && <th>Tarix</th>}
                                              {selectedFields?.price && <th>Dəyəri</th>}
                                              {selectedFields?.visa && <th>Visa tələbi</th>}
                                              {selectedFields?.documentStatus && <th>Sənədlərin statusu</th>}
                                              {selectedFields?.status && <th>Qəbul prosesi</th>}
                                              {selectedFields?.sendedDocumentAddress &&
                                                <th>Sənədlər kimə/hara göndərildi</th>}
                                              {selectedFields?.educationType && <th>Əyani/Qiyabi/Distant</th>}
                                              {selectedFields?.degree && <th>Dərəcə</th>}
                                              {selectedFields?.country && <th>Ölkə</th>}
                                              {selectedFields?.university && <th>Universitetlər</th>}
                                              {selectedFields?.specialty && <th>İxtisaslar</th>}
                                              {selectedFields?.note && <th>Qeyd</th>}
                                              {selectedFields?.lastLog && <th>Sonuncu tarixçə</th>}
                                              {selectedFields?.missingFiles && <th>Əksik sənədlər</th>}
                                              {selectedFields?.uploadedFiles && <th>Yüklənmiş fayllar</th>}
                                              <th>Yaradılma tarixi</th>
                                              <th>Yenilənmə tarixi</th>
                                              <th className="sticky-right-td">Əməliyyatlar</th>
                                          </tr>
                                          </thead>
                                          <tbody>
                                          {data?.map(item => (
                                            <tr key={item.id}>
                                                {selectedFields?.id && <td>{item.index}</td>}
                                                {selectedFields?.user && <td>{item.user}</td>}
                                                {selectedFields?.fullName && <td>{item.fullName}</td>}
                                                {selectedFields?.birthDate && <td>{item.birthDate}</td>}
                                                {selectedFields?.passportNumber &&
                                                  <td>{item.passportNumber}</td>}
                                                {selectedFields?.fatherName && <td>{item.fatherName}</td>}
                                                {selectedFields?.motherName && <td>{item.motherName}</td>}
                                                {selectedFields?.phone && <td>{item.phone}</td>}
                                                {selectedFields?.code && <td>{item.code}</td>}
                                                {selectedFields?.contractDate &&
                                                  <td>{item.contractDate}</td>}
                                                {selectedFields?.price && <td>{item.price}</td>}
                                                {selectedFields?.visa && <td>{item.visa}</td>}
                                                {selectedFields?.documentStatus &&
                                                  <td>{item.documentStatus}</td>}
                                                {selectedFields?.status && <td>{item.status}</td>}
                                                {selectedFields?.sendedDocumentAddress &&
                                                  <td>{item.sendedDocumentAddress}</td>}
                                                {selectedFields?.educationType &&
                                                  <td>{item.educationType}</td>}
                                                {selectedFields?.degree && <td>{item.degree}</td>}
                                                {selectedFields?.country && <td>{item.country}</td>}
                                                {selectedFields?.university && <td>{item.university}</td>}
                                                {selectedFields?.specialty && <td>{item.specialty}</td>}
                                                {selectedFields?.note && <td>{item.note}</td>}
                                                {selectedFields?.lastLog && <td>{item.lastLog}</td>}
                                                {selectedFields?.missingFiles && <td>{item.missingFiles}</td>}
                                                {selectedFields?.uploadedFiles &&
                                                  <td>{item.uploadedFiles}</td>}
                                                <td>{item.createdAt}</td>
                                                <td>{item.updatedAt}</td>
                                                <td className="sticky-right-td">{item.actions}</td>
                                            </tr>
                                          ))}
                                          </tbody>
                                      </table>
                                    )}
                                </div>
                                <CustomPagination
                                  page={page}
                                  limit={limit}
                                  setLimit={setLimit}
                                  setPage={setPage}
                                  total={total}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Contracts
