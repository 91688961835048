import React from "react"
import {Col, Container, Row} from "reactstrap"

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="footer">
                <Container fluid={true}>
                    <Row>
                        <Col md={6}>{new Date().getFullYear()} © <a href="https://azstudy.az">Azstudy</a>.</Col>
                        <Col md={6}>
                            <div className="text-sm-end d-none d-sm-block">
                                Design & Develop by Farman Allahverdiyev
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    )
}

export default Footer
