import React, {useEffect} from "react";
import {Navigate} from "react-router-dom";
import Auth from 'api/auth'
import LocalStorageManager from "../helpers/localStorageManager";
import PermissionDenied from "../pages/PermissionDenied";

const Authmiddleware = (props) => {
    const token = localStorage.getItem("token")
    const access = LocalStorageManager.get('user')?.permissionId?.access
    if (!token) {
        return (
            <Navigate to={{pathname: "/login", state: {from: props.location}}}/>
        );
    }

    if (props?.name && !access?.[props.name]?.can_view) {
        return <PermissionDenied/>
    }

    const auth = async () => {
        const {data} = await Auth.auth({id: LocalStorageManager.get('user')?.id})
        LocalStorageManager.set('user', data)
    }

    useEffect(() => {
        const noScrollInputs = document.querySelectorAll('input[type=number]');

        noScrollInputs.forEach(item => {
            item.addEventListener('wheel', (e) => {
                e.preventDefault();
            });
        })
        if (token) {
            auth()
        }
    }, [])

    return (<React.Fragment>
        {props.children}
    </React.Fragment>);
};

export default Authmiddleware;
