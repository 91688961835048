import Breadcrumbs from "../../components/Common/Breadcrumb";
import {useEffect, useState} from "react";
import {Badge, Button, Card, CardBody, Col, Input, Modal, ModalHeader, Row, UncontrolledTooltip} from "reactstrap";
import Appeal from '../../api/appeal'
import moment from "moment";
import Select from "react-select";
import appealStatuses from '../../data/appealStatuses.json'
import ConfirmModal from "../../components/Common/ConfirmModal";
import {toast} from "react-toastify";
import SettingsModal from "./SettingsModal";
import FieldSettings from "../../api/field-settings";
import modules from '../../data/modules.json'
import Can from "../../components/Permissions/Can";
import CustomPagination from "../../components/CustomPagination";
import {useForm} from "react-hook-form";
import Filters from "./Filters";
import AddAppeal from "./AddAppeal";
import Form from "../../helpers/form";

const Appeals = () => {
    const [data, setData] = useState([])
    const [isFetching, setIsFetching] = useState(true)
    const [confirmModal, setConfirmModal] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [settingsModal, setSettingsModal] = useState(false)
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [sort, setSort] = useState({})
    const [selectedFields, setSelectedFields] = useState({
        id: true,
        fullName: true,
        user: true,
        status: true,
        nextCallDate: true,
        lastLog: true,
    })

    const [appealForm, setAppealForm] = useState({})

    document.title = 'Müraciətlər | Azstudy'

    const filterForm = useForm({
        defaultValues: {
            expertId: null,
            status: null,
            statusDate: null,
            nextCallDate: null,
            nextCallDateType: null,
        }
    })

    const changeSelectValue = async (data, id, key, reset) => {
        await Appeal.update({
            [key]: data?.value
        }, id)
        reset && fetchAppeals(false, searchValue ? {search: searchValue} : null)
    }


    const formatData = data => {
        setTotal(data?.total)
        setData(data?.data?.map((item, index) => {
            const status = appealStatuses.find(status => status.value === item.status)
            const isNextCallDateValid = item?.nextCallDate ? moment(item.nextCallDate).isSameOrAfter(new Date().setHours(0, 0, 0, 0)) : false
            return {
                ...item,
                index: <Badge color="primary">{index + 1}</Badge>,
                status: <Select
                    className="w-400px"
                    onChange={e => changeSelectValue(e, item.id, 'status', true)}
                    options={appealStatuses}
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            backgroundColor: status?.color || '#fff',
                            width: '400px',
                        }),
                    }}
                    defaultValue={status}/>,
                lastLog: item.log_ids?.[item.log_ids.length - 1]?.log,
                phones: <div className="d-flex flex-column gap-1">
                    {item.phones.map(phone => (
                        <span key={phone.phone}><a href={`tel:${phone.phone}`}>
                            <Badge color="warning">{phone.phone}</Badge>
                        </a></span>
                    ))}
                </div>,
                expert: item?.expertId && `${item?.expertId?.name} ${item?.expertId?.surname}`,
                nextCallDate: item?.nextCallDate ? {
                    color: isNextCallDateValid ? '#8ee000' : '#ff0b00',
                    date: moment(item.nextCallDate).format('DD.MM.YYYY')
                } : null,
                user: `${item?.user_id?.name} ${item?.user_id?.surname}`,
                createdAt: moment(item.createdAt).format('DD.MM.YYYY HH:mm'),
                updatedAt: moment(item.updatedAt).format('DD.MM.YYYY HH:mm'),
                actions: <div className="d-flex gap-1">
                    <Can moduleName={modules.appeals.value} keys={['can_update']}>
                        <Button
                            onClick={() => setAppealForm({status: true, data: item.id})}
                            type="button"
                            color="primary"
                            id={`appeal-${item.id}-edit`}
                        >
                            <i className="bx bx-pencil"/>
                        </Button>
                        <UncontrolledTooltip target={`appeal-${item.id}-edit`} placement="bottom">
                            Düzəliş et
                        </UncontrolledTooltip>
                    </Can>
                    <Can moduleName={modules.appeals.value} keys={['can_delete']}>
                        <Button
                            type="button"
                            color="danger"
                            id={`appeal-${item.id}-delete`}
                            onClick={() => setConfirmModal(item.id)}
                        >
                            <i className="bx bx-trash"/>
                        </Button>
                        <UncontrolledTooltip target={`appeal-${item.id}-delete`} placement="bottom">
                            Sil
                        </UncontrolledTooltip>
                    </Can>
                </div>
            }
        }))
    }

    const search = async e => {
        e.preventDefault()
        if (!searchValue.length) {
            fetchAppeals()
            return
        }
        if (isNaN(Number(searchValue)) && searchValue.length && searchValue.length < 3) {
            return toast.error('Minimum 3 hərf daxil edin')
        }
        setIsFetching(true)
        try {
            const {data} = await Appeal.search({search: searchValue})
            formatData(data)
        } catch (e) {
            toast.error(e)
        } finally {
            setIsFetching(false)
        }
        setIsFetching(false)
    }

    const fetchAppeals = async (showLoader = true) => {
        setIsFetching(showLoader)
        const params = filterForm.getValues()
        const {data} = await Appeal.get({
            page, limit, sort, ...{
                status: params?.status?.value,
                statusDate: Form.convertFormDate(params.statusDate),
                expertId: params?.expertId?.value,
                nextCallDate: Form.convertFormDate(params.nextCallDate),
                nextCallDateType: params?.nextCallDateType?.value
            }
        })
        formatData(data)
        setIsFetching(false)
    }

    const fetchFieldSettings = async () => {
        const fieldSettings = await FieldSettings.get({moduleName: modules.appeals.value})
        fieldSettings?.data && setSelectedFields(fieldSettings?.data?.fields)
    }

    const deleteAppeal = async () => {
        await Appeal.delete(confirmModal)
        fetchAppeals()
    }

    const handleSort = field => {
        setSort(prev => ({
            ...prev,
            [field]: prev?.[field] === 1 ? -1 : 1
        }))
    }


    useEffect(() => {
        fetchFieldSettings()
    }, [])

    useEffect(() => {
        fetchAppeals(true)
    }, [page, limit, sort])

    return (
        <div className="page-content">
            <ConfirmModal active={confirmModal} setActive={setConfirmModal} callback={deleteAppeal}/>
            <Modal className="modal-dialog-centered" isOpen={settingsModal} toggle={() => setSettingsModal(false)}>
                <ModalHeader
                    toggle={() => setSettingsModal(false)}>Tənzimləmələri dəyiş</ModalHeader>
                <SettingsModal fetchData={fetchFieldSettings} selectedFields={selectedFields}
                               setModal={setSettingsModal}/>
            </Modal>
            <Modal size="lg" className="modal-dialog-centered" isOpen={appealForm?.status}
                   toggle={() => setAppealForm({})}>
                <ModalHeader
                    toggle={() => setAppealForm({})}>{appealForm?.data ? 'Müraciəti yenilə' : 'Əlavə et'}</ModalHeader>
                <AddAppeal fetchAppeals={fetchAppeals} setModal={setAppealForm} modal={appealForm}/>
            </Modal>
            <div className="container-fluid">
                <Breadcrumbs breadcrumbItem={`MÜRACİƏTLƏR (${total})`}/>
                <Row>
                    <Col sm={12}>
                        <Filters form={filterForm} fetchData={fetchAppeals}/>
                    </Col>
                    <Col sm={12}>
                        <Card>
                            <CardBody>
                                <div
                                    className="d-flex flex-column-reverse flex-md-row align-items-start justify-content-between gap-2 mb-3">
                                    <div className="d-flex flex-column flex-md-row gap-1 w-100">
                                        <Can moduleName={modules.appeals.value} keys={['can_add']}>
                                            <Button
                                                onClick={() => setAppealForm({status: true})}
                                                type="button"
                                                color="success"
                                                className="btn-label"
                                            >
                                                <i className="bx bx-plus label-icon"/> Əlavə et
                                            </Button>
                                        </Can>
                                        <Button
                                            onClick={() => setSettingsModal(true)}
                                            type="button"
                                            color="secondary"
                                            className="btn-label"
                                        >
                                            <i className="bx bx-cog label-icon"/> Tənzimləmələr
                                        </Button>
                                    </div>
                                    <form onSubmit={search} className="app-search p-0">
                                        <div className="position-relative">
                                            <Input disabled={isFetching} placeholder="Axtar" value={searchValue}
                                                   onChange={e => setSearchValue(e.target.value)} id="search-input-2"/>
                                            <UncontrolledTooltip target="search-input-2" placement="bottom">
                                                Müraciətçi,tələbə, telefon, marağı
                                            </UncontrolledTooltip>
                                            <span onClick={e => {
                                                if (!isFetching) {
                                                    search(e)
                                                }
                                            }}
                                                  class="bx bx-search-alt cursor-pointer"/>
                                        </div>
                                    </form>
                                </div>
                                <div className="table-responsive" style={{paddingBottom: '270px'}}>
                                    {isFetching ? (
                                        Array.from({length: 10}).map((item, index) => (
                                            <p className="placeholder-glow mb-2" key={index}>
                                                <span className="placeholder py-4 col-12"/>
                                            </p>
                                        ))
                                    ) : data?.length > 0 && (
                                        <>
                                            <table className="d-none d-md-table table table-bordered">
                                                <thead>
                                                <tr>
                                                    {selectedFields?.id && <th>ID</th>}
                                                    {selectedFields?.user && <th>İstifadəçi</th>}
                                                    {selectedFields?.fullName && <th>Tələbənin ad soyadı</th>}
                                                    {selectedFields?.expert && <th>Mütəxəssis</th>}
                                                    {selectedFields?.status && <th>Status</th>}
                                                    {selectedFields?.applicantFullName &&
                                                        <th>Müraciətçinin ad soyadı</th>}
                                                    {selectedFields?.appealSource && <th>Hardan tapıb</th>}
                                                    {selectedFields?.interest && <th>Marağı</th>}
                                                    {selectedFields?.nextCallDate && <th>
                                                        <div className="d-flex gap-2 align-items-start">
                                                            <span>Növbəti zəng tarixi</span>
                                                            <i onClick={() => handleSort('nextCallDate')}
                                                               className={`text-primary cursor-pointer bx bx-sort-${sort?.nextCallDate === 1 ? 'down' : 'up'} font-size-18`}/>
                                                        </div>
                                                    </th>}
                                                    {selectedFields?.phones && <th>Telefonlar</th>}
                                                    {selectedFields?.lastLog && <th>Sonuncu tarixçə</th>}
                                                    <th>
                                                        <div className="d-flex gap-2 align-items-start">
                                                            <span>Yaradılma tarixi</span>
                                                            <i onClick={() => handleSort('createdAt')}
                                                               className={`text-primary cursor-pointer bx bx-sort-${sort?.createdAt === 1 ? 'down' : 'up'} font-size-18`}/>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className="d-flex gap-2 align-items-start">
                                                            <span>Yenilənmə tarixi</span>
                                                            <i onClick={() => handleSort('updatedAt')}
                                                               className={`text-primary cursor-pointer bx bx-sort-${sort?.updatedAt === 1 ? 'down' : 'up'} font-size-18`}/>
                                                        </div>
                                                    </th>
                                                    <th className="sticky-right-td">Əməliyyatlar</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {data?.map((item) => (
                                                    <tr key={item.id}>
                                                        {selectedFields?.id && <td>{item.index}</td>}
                                                        {selectedFields?.user && <td>{item.user}</td>}
                                                        {selectedFields?.fullName && <td>{item.fullName}</td>}
                                                        {selectedFields?.expert && <td>{item.expert}</td>}
                                                        {selectedFields?.status && <td>{item.status}</td>}
                                                        {selectedFields?.applicantFullName &&
                                                            <td>{item.applicantFullName}</td>}
                                                        {selectedFields?.appealSource && <td>{item.appealSource}</td>}
                                                        {selectedFields?.interest && <td>{item.interest}</td>}
                                                        {selectedFields?.nextCallDate &&
                                                            <td style={{
                                                                backgroundColor: item.nextCallDate?.color,
                                                                color: '#fff'
                                                            }}>{item.nextCallDate?.date}</td>}
                                                        {selectedFields?.phones && <td>{item.phones}</td>}
                                                        {selectedFields?.lastLog &&
                                                            <td style={{backgroundColor: '#ffd900'}}>{item.lastLog}</td>}
                                                        <td>{item.createdAt}</td>
                                                        <td>{item.updatedAt}</td>
                                                        <td className="sticky-right-td">{item.actions}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                            <div className="d-block d-md-none">
                                                {data.map(item => (
                                                    <div key={item.id}
                                                         className="d-flex flex-column py-1 px-2 gap-1 border border-primary">
                                                        <div className="d-flex justify-content-between">
                                                            <span>Ad soyad</span>
                                                            <b>{item.applicantFullName}</b>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <span>Telefon</span>
                                                            <span>{item.phones}</span>
                                                        </div>
                                                        <div className="d-flex justify-content-end">
                                                            {item.actions}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    )}
                                </div>
                                <CustomPagination
                                    page={page}
                                    limit={limit}
                                    setLimit={setLimit}
                                    setPage={setPage}
                                    total={total}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Appeals
