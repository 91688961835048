import {Card, CardBody, CardHeader, CardTitle, UncontrolledTooltip} from "reactstrap";
import User from "../../helpers/user";
import Task from "./Task";

const Group = (
    {
        groups,
        item,
        setGroupModal,
        setConfirmModal,
        setTaskModal,
        setTaskConfirmModal,
    }
) => {
    return (
        <Card className="container-card" key={item.id}>
            <CardHeader>
                <CardTitle className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center gap-1">
                        <span>{item.name}</span>
                        {(User.isAdmin() || User.isSuperAdmin()) && (
                            <i onClick={() => setGroupModal({status: true, data: item})}
                               className="bx bx-pencil text-primary cursor-pointer"/>
                        )}
                        {(User.isAdmin() || User.isSuperAdmin()) && (
                            <i onClick={() => setConfirmModal(item.id)}
                               className="bx bx-trash text-danger cursor-pointer"/>
                        )}
                    </div>
                    <button id={`plus-button-${item.id}`}
                            onClick={() => setTaskModal({status: true, data: {groupId: item.id}})}
                            className="d-flex bg-transparent border-0"><i
                        className="bx bx-plus text-success font-size-18"/>
                    </button>
                    <UncontrolledTooltip
                        placement="bottom" target={`plus-button-${item.id}`}>
                        Əlavə et
                    </UncontrolledTooltip>
                </CardTitle>
            </CardHeader>
            <CardBody>
                {item.tasks.map((task) => (
                    <Task key={task.id} setTaskModal={setTaskModal} item={item} task={task}
                          setTaskConfirmModal={setTaskConfirmModal}/>
                ))}
            </CardBody>
        </Card>
    )
}

export default Group
